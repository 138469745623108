import { Market } from '@compstak/common';
import { MultiMarketUpgradeModal } from 'Components/Modals/UpgradeModal/MultiMarketUpgradeModal';
import { PermissionsState, usePermissions } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import { useModal } from 'providers/ModalProvider';
import { useMemo } from 'react';
import { CompType } from 'types';

export const isMarketAccessible = (
	marketId: number | string,
	compType: CompType,
	permissions: PermissionsState
) => {
	const permission = compType === 'sale' ? 'salesAccess' : 'leaseAccess';

	return permissions[marketId]?.[permission];
};

export const useIsMarketsAccessible = ({
	marketIds,
	compType,
}: {
	marketIds: number[];
	compType: CompType;
}) => {
	const permissions = usePermissions();

	return useMemo(() => {
		return marketIds.some((marketId) =>
			isMarketAccessible(marketId, compType, permissions)
		);
	}, [marketIds, compType, permissions]);
};

/**
 * @fires modal if there is no permission
 */
export const useCheckMarketAccess = () => {
	const permissions = usePermissions();
	const { openModal, closeModal } = useModal();

	const checkMarketAccess = ({
		marketId,
		compType,
	}: {
		marketId: number;
		compType: CompType;
	}) => {
		if (!isMarketAccessible(marketId, compType, permissions)) {
			openModal({
				modalContent: (
					<MultiMarketUpgradeModal
						compType={compType}
						onClose={closeModal}
						selectedMarkets={[marketId]}
					/>
				),
			});
			return false;
		}

		return true;
	};

	return {
		checkMarketAccess,
	};
};

export const usePortfolioAccessibleMarkets = ({
	compType = 'lease',
	portfolioMarkets,
}: {
	portfolioMarkets: Market[];
	compType?: CompType;
}) => {
	const permissions = usePermissions();
	const markets = useMarkets();

	const allFilteredMarkets = markets.list.filter((market) =>
		isMarketAccessible(market.id, compType, permissions)
	);

	const filteredPortfolioMarkets = markets.list.filter((market) => {
		return (
			portfolioMarkets.some((pMarket) => pMarket.id === market.id) &&
			isMarketAccessible(market.id, compType, permissions)
		);
	});

	return portfolioMarkets.length === 0
		? allFilteredMarkets
		: filteredPortfolioMarkets;
};
