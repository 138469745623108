import { nullValueText } from '@compstak/common';
import {
	formatSortArray,
	formatBool,
	formatBuildingRail,
	formatBuildingCeilingHeight,
	formatCompQuarter,
	formatCsv,
	formatLeaseEscalations,
	formatMonths,
	formatPercent,
	formatRentBumpsCombined,
	formatRentBumpsPercent,
	formatObfuscatedSizeRange,
	formatSqft,
} from 'format';
import { Comp, CompKeys } from 'types/comp';
import {
	allAttributes as originalAllAttributes,
	AttributeType,
	hash,
	list,
	Locale,
} from '../attributes/index';
import { clone } from './clone';
import { MUFA_SPACE_TYPE_MONEY_ATTRIBUTES_LIST } from './constants';
import date from './util/calendarDate';
import floorDetails from './util/floorDetails';
import money from './util/money';
import number from './util/number';
import { TableCompKeys, TableCompValues } from 'types/table';
import { SalesAttributeToPlotValues } from 'Pages/Analytics/Builder/chartBuilderConstants';

function compHasOneOf(comp: Comp, fields: string | any[]) {
	for (let i = 0; i < fields.length; i++) {
		if (comp.hasOwnProperty(fields[i])) {
			return true;
		}
	}
	return false;
}

function rentBumpsCombined(comp: Comp) {
	// ["rentBumpsPercent", "rentBumpsDollar", "rentBumpYears", "leaseEscalations"]

	// it's a bug if a comp has leaseEscalations and anything else.
	// @ts-expect-error TS2339: Property 'leaseEscalations' do...
	if (comp.leaseEscalations) {
		return formatCompValue(comp, 'leaseEscalations', 'lease');
	}

	switch (true) {
		// @ts-expect-error TS2339: Property 'leaseEscalations' do...
		case comp.leaseEscalations === null:
		// @ts-expect-error TS2339: Property 'rentBumpsPercent' do...
		case comp.rentBumpsPercent === null:
		// @ts-expect-error TS2339: Property 'rentBumpsDollar' doe...
		case comp.rentBumpsDollar === null:
		// @ts-expect-error TS2339: Property 'rentBumpYears' does ...
		case comp.rentBumpYears === null:
			return null;
	}

	return formatRentBumpsCombined(comp);
}

function insideviewNaicSic(comp: Comp) {
	// @ts-expect-error TS2339: Property 'insideviewNaics' doe...
	if (comp.insideviewNaics || comp.insideviewSic) {
		// @ts-expect-error TS2339: Property 'insideviewNaics' doe...
		return `${comp.insideviewNaics ?? nullValueText} / ${
			// @ts-expect-error TS2339: Property 'insideviewSic' does ...
			comp.insideviewSic ?? nullValueText
		}`;
	}

	return undefined;
}

export const combined = {
	rentBumpsCombined: rentBumpsCombined,
	insideviewNaicSic,
};

const perYearWording = function (years: string | number) {
	if (years === 1) {
		return 'per year';
	}
	return 'every ' + years + ' years';
};

export const allAttributes = originalAllAttributes;

export const getAttributeHash = function (
	type: AttributeType,
	locale: Locale = 'en_US'
) {
	return hash(type, locale);
};

export const formatValue = function (
	propertyName: TableCompKeys,
	value: TableCompValues,
	inMonthlyMarket: boolean | undefined,
	type: AttributeType,
	isAnalyticsTable?: boolean,
	options?: { isYAxesFormatting?: boolean },
	locale: Locale = 'en_US'
) {
	if (value === null) {
		return value;
	}

	if (propertyName === 'portfolio' && Array.isArray(value)) {
		return value.length;
	}

	const currency = locale === 'en_GB' ? 'GBP' : 'USD';
	// @ts-expect-error TS7015: Element implicitly has an 'any...
	let attribute = hash(type)?.[propertyName];

	if (
		typeof value === 'number' &&
		inMonthlyMarket &&
		attribute &&
		attribute.canBeMonthly
	) {
		value = value / 12;
	}

	// special cases of missing attributes on the list that need to be formatted properly
	// I'm adding the !attribute just in case that they get added later
	if (
		!attribute &&
		[...MUFA_SPACE_TYPE_MONEY_ATTRIBUTES_LIST, 'dateCreated', 'ti'].indexOf(
			propertyName
		) !== -1
	) {
		attribute = { name: propertyName };
	}

	if (!attribute && propertyName === 'occupancy') {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = formatPercent(value);
	}

	// if we couldn't find the attribute on the list, we can't format the value
	if (!attribute) {
		return value;
	}

	// currentRent and ti don't have preUnit
	if (
		value !== null &&
		[
			...MUFA_SPACE_TYPE_MONEY_ATTRIBUTES_LIST,
			'currentRent',
			'effectiveRent',
			'ti',
			'workValue',
		].indexOf(attribute.name) !== -1
	) {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = money(value, currency);
	}

	// buildingOfficePortion doesn't have a postUnit, so we need to add the SqFt manually. also, the
	// backend is sending us 'sqft' and we're showing SqFt everywhere, so we'll convert in that particular case
	if (attribute.name === 'buildingOfficePortion') {
		// @ts-expect-error TS18048: 'value' is possibly 'undefined...
		value = number(value.value) + ' ' + attribute.postUnit;
	}

	// because the date is now ISO 8601, we need to transform it to the american format of MM/DD/YYYY
	if (value !== null && attribute.jsType === 'date') {
		value = date(value, true, locale); // the second argument will make the year appear with 4 digits
	}

	// handle date array fields
	if (value !== null && attribute.jsType === 'dateArray') {
		// @ts-expect-error TS18048: 'value' is possibly 'undefined...
		value.sort(); //sort dates
		// @ts-expect-error TS18048: 'value' is possibly 'undefined...
		value = value
			// @ts-expect-error TS2339: Property 'map' does not exist ...
			.map(function (v: any) {
				return date(v, true, locale);
			})
			.join('; ');
	}
	if (value !== null && attribute.jsType === 'integer') {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = number(Math.round(value));
	}

	// special cases when we receive a object { numberOfSpots: value, pricePerSpot: value }
	if (
		['buildingParkingReserved', 'buildingParkingUnreserved'].indexOf(
			attribute.name
		) !== -1
	) {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		if (value.numberOfSpots && value.pricePerSpot) {
			// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
			value = value.numberOfSpots + '/' + money(value.pricePerSpot, currency);
		}
	}

	// executionQuarter special case
	if (['executionQuarter', 'saleQuarter'].indexOf(attribute.name) !== -1) {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = formatCompQuarter(value);
	}

	// leaseEscalations special case: array of objects like { dollars: value, months: value }
	if (attribute.name === 'leaseEscalations') {
		// @ts-expect-error whatever
		value = formatLeaseEscalations(value, inMonthlyMarket);
	}

	// floorOccupancies special case. Fortunately the backend sends the formatted value on the payload
	if (attribute.name === 'floorOccupancies') {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = value.formatted;
	}

	// mainFloorDetails special case.
	if (attribute.name === 'mainFloorDetails' && value) {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = floorDetails(value);
	}

	// otherFloorsDetails - similar to mainFloorDetails, just an array
	if (attribute.name === 'otherFloorsDetails') {
		value = formatCsv(
			// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
			value
				// @ts-expect-error TS2339: Property 'map' does not exist ...
				.map(function (floor: { floors: any[]; size: any; takingRent: any }) {
					return floorDetails(floor);
				})
		);
	}

	// rentBumpsDollar are always yearly (https://compstak.atlassian.net/wiki/display/DATA/Rent+Bump+Years)
	if (attribute.name === 'rentBumpsDollar') {
		const bumps =
			attribute.canBeMonthly && inMonthlyMarket
				? // @ts-expect-error TS18049: 'value' is possibly 'null' or ...
					value.bumps / 12
				: // @ts-expect-error TS18049: 'value' is possibly 'null' or ...
					value.bumps;
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = money(bumps, currency) + ' ' + perYearWording(value.months / 12);
	}

	// Some values are now sent as an array. No need to create a util function that only implements join
	if (
		[
			'landlordRealtyBrokers',
			'landlordRealtyCompanies',
			'tenantRealtyBrokers',
			'tenantRealtyCompanies',
			'landlordName',
			'rentBumpYears',
		].indexOf(attribute.name) !== -1
	) {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = formatCsv(value);
	}

	if (
		typeof value === 'number' &&
		['freeMonths', 'leaseTerm', 'additionalFreeMonths'].indexOf(
			attribute.name
		) !== -1
	) {
		value = formatMonths(value);
	}

	if (
		['buildingParkingRatio', 'lotSize', 'floorAreaRatio'].indexOf(
			attribute.name
		) !== -1
	) {
		value = Number(value).toFixed(2);
	}

	if (
		[
			'buildingLoadFactor',
			'interestPercentage',
			'capRate',
			'percentOccupied',
			'percentImproved',
		].indexOf(attribute.name) !== -1
	) {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = formatPercent(value / 100);
	}

	// rentBumpsPercent are similar to rentBumpsDollar (see above) - but the bump value is already a %
	if (attribute.name === 'rentBumpsPercent') {
		// @ts-expect-error TS18049: 'value' is possibly 'null' or ...
		value = formatRentBumpsPercent(value);
	}

	if (attribute.name === 'proRataPercent') {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = formatPercent(value, 1);
	}

	if (attribute.name === 'occupancy') {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = formatPercent(value);
	}

	if (['concessionsPercentage'].indexOf(attribute.name) !== -1) {
		// @ts-expect-error TS2362: The left-hand side of an arith...
		value = (value * 100).toFixed(2);
	}
	// Also, years are a special case, we want to see 1930 not 1,930 as the year
	if (
		typeof value === 'number' &&
		['buildingYear', 'buildingYearRenovated'].indexOf(attribute.name) === -1
	) {
		value = number(value);
	}

	if (attribute.name === 'insideviewEmployees') {
		// @ts-expect-error TS2345: Argument of type 'string | num...
		value = number(value);
	}

	// simple yes/no cases
	if (
		[
			'buildingSellingBasement',
			'buildingSprinkler',
			'buildingVentedSpace',
			'buildingCornerUnit',
			'sublease',
			'includeBusinesses',
			'multiTenant',
		].indexOf(attribute.name) !== -1
	) {
		value = formatBool(!!value);
	}

	if (
		value &&
		attribute.preUnit &&
		!['currentRent', 'effectiveRent'].includes(attribute.name)
	) {
		if (
			(attribute.preUnit === '$' || attribute.preUnit === '£') &&
			![
				SalesAttributeToPlotValues.SALES_PRICE,
				SalesAttributeToPlotValues.SALES_VOLUME,
			].includes(attribute.name)
		) {
			// @ts-expect-error TS2345: Argument of type 'string | num...
			value = money(value, currency, attribute.decimalPrecision);
		} else {
			value = attribute.preUnit + value;
		}
	}
	// Array.isArray is a hack to get phantom to stop complaining
	// these attributes should always have array values
	if (
		[
			'buyer',
			'buyerRepBrokers',
			'buyerRepCompanies',
			'recordedBuyer',
			'recordedSeller',
			'seller',
			'sellerRepBrokers',
			'sellerRepCompanies',
		].indexOf(attribute.name) !== -1 &&
		Array.isArray(value)
	) {
		value = formatCsv(value as string[] | number[]);
	}

	if (
		attribute.name === 'startingRent' ||
		attribute.name === 'effectiveRent' ||
		attribute.name === 'reportedRentWithDate'
	) {
		if (inMonthlyMarket) {
			value += ' (Monthly)';
		} else {
			value += ' (Annual)';
		}
	}

	if (type === 'sale') {
		if (['salePricePsf', 'totalSalePrice'].includes(attribute.name)) {
			const decimalPrecision = attribute.name === 'salePricePsf' ? 2 : 0;
			const isYAxesFormatting = options?.isYAxesFormatting;
			// @ts-expect-error TS2345: Argument of type 'string | num...
			value = money(value, currency, decimalPrecision, { isYAxesFormatting });
		}
		if (
			['buildingSize', 'propertyAverageTransactionSize'].includes(
				attribute.name
			)
		) {
			value += ' SqFt';
		} else if (
			[
				'salePricePsf',
				'propertyMarketStartingRent',
				'propertyMarketEffectiveRent',
			].includes(attribute.name) &&
			!isAnalyticsTable
		) {
			value += ' PSF';
		}
	}

	if (attribute.name === 'insideviewTickers' && value) {
		// @ts-expect-error TS2339: Property 'length' does not exi...
		if (value.length) {
			// @ts-expect-error TS2339: Property 'join' does not exist...
			value = formatCsv(value);
		} else {
			value = undefined;
		}
	}

	if (attribute.name === 'insideviewWebsites' && value) {
		// @ts-expect-error TS2339: Property 'length' does not exi...
		if (value.length) {
			// @ts-expect-error TS2339: Property 'join' does not exist...
			value = formatCsv(value);
		} else {
			value = undefined;
		}
	}

	if (attribute.name === 'insideviewStatus' && value === 'OutOfBusiness') {
		value = 'Out of Business';
	}

	if (value == null) {
		return '';
	}

	return Array.isArray(value) ? formatSortArray(value) : value;
};

const formatCompValue = function (
	comp: Comp,
	propertyName: CompKeys,
	type: AttributeType
) {
	return formatValue(
		propertyName,
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		comp[propertyName],
		// @ts-expect-error TS2339: Property 'inMonthlyMarket' doe...
		comp.inMonthlyMarket,
		type,
		undefined,
		undefined,
		// @ts-expect-error TS2339: Property 'locale' does not exi...
		comp.locale ?? 'en_US'
	);
};

export type FormattedComp = {
	name: string;
	displayName: string;
	value: any;
	valueExists: boolean;
	hideIfBlank?: boolean;
	showAlertIfNotPresent?: boolean;
	section?: string;
	width: number;
	showLock: boolean;
	postUnit: string | null;
	inTableView?: boolean;
	inDetailScreen?: boolean;
};

export const format = function (
	comp: Comp,
	type: AttributeType,
	overrideInMonthlyMarket?: boolean
): FormattedComp[] {
	if (!comp || typeof comp !== 'object') {
		throw new Error('No comp was given to be formatted.');
	}

	const typeMap: Record<string, AttributeType> = {
		lease: 'lease',
		sales: 'sale',
		sale: 'sale',
		property: 'property',
	};
	const mappedType = typeMap[type];
	if (!mappedType) {
		throw new Error(
			'The given type ' +
				type +
				' is not a valid choice of: ' +
				Object.keys(typeMap)
		);
	}

	// @ts-expect-error TS2339: Property 'locale' does not exi...
	const attirbutes = list(mappedType, comp.locale ?? 'en_US');

	return attirbutes
		.filter((attribute) => {
			if (attribute.passthrough === true) {
				return false;
			}

			if (attribute.hidden) {
				return false;
			}

			if (attribute.hideIfBlank === false) {
				return true;
			}

			if (!comp.hasOwnProperty(attribute.name) && !attribute.isCombined) {
				return false;
			}

			if (
				attribute.requiresOneOf &&
				!compHasOneOf(comp, attribute.requiresOneOf)
			) {
				return false;
			}

			return true;
		})
		.map((attributeSettings) => {
			return formatCompAttribute({
				comp,
				attributeSettings,
				overrideInMonthlyMarket,
				compType: mappedType,
			});
		});
};

export const formatCompAttribute = function ({
	comp,
	attributeSettings,
	compType,
	overrideInMonthlyMarket,
}: {
	comp: Comp;
	attributeSettings: ReturnType<typeof list>[number];
	compType: AttributeType;
	overrideInMonthlyMarket?: boolean;
}): FormattedComp {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	let value = comp[attributeSettings.name];

	if (attributeSettings.name === 'buildingRail') {
		value = formatBuildingRail(value);
	}

	if (attributeSettings.name === 'transactionSize' && value) {
		value =
			'own' in comp && comp.own
				? formatSqft(value)
				: formatObfuscatedSizeRange(value);
	}

	if (value !== null && value !== undefined) {
		value = formatValue(
			attributeSettings.name as TableCompKeys,
			value,
			// @ts-expect-error TS2339: Property 'inMonthlyMarket' doe...
			overrideInMonthlyMarket ?? comp.inMonthlyMarket,
			compType,
			undefined,
			undefined,
			// @ts-expect-error TS2339: Property 'locale' does not exi...
			comp.locale ?? 'en_US'
		);
	} else if (attributeSettings.isCombined) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		value = combined[attributeSettings.name](comp);
	}

	let displayName: string;
	if (
		attributeSettings.postUnit &&
		attributeSettings.displayName.indexOf(attributeSettings.postUnit) === -1
	) {
		displayName =
			attributeSettings.displayName + ' (' + attributeSettings.postUnit + ')';
	} else {
		displayName = attributeSettings.displayName ?? '';
	}

	if (attributeSettings.name === 'buildingCeilingHeight') {
		value = formatBuildingCeilingHeight(value);
	}

	if (attributeSettings.name === 'buildingSize') {
		if (value === '0') {
			value = nullValueText;
		}
	}

	return {
		name: attributeSettings.name,
		displayName: displayName,
		value: value,
		valueExists: value !== undefined,
		hideIfBlank: attributeSettings.hideIfBlank,
		showAlertIfNotPresent: attributeSettings.showAlertIfNotPresent,
		section: attributeSettings.section,
		width: attributeSettings.width,
		showLock: value === null,
		postUnit: attributeSettings.postUnit || null,
		inTableView:
			'inTableView' in attributeSettings
				? (attributeSettings.inTableView as boolean)
				: undefined,
		inDetailScreen:
			'inDetailScreen' in attributeSettings
				? (attributeSettings.inDetailScreen as boolean)
				: undefined,
	};
};

export const formatComp = function (
	comp: Comp,
	type: AttributeType,
	overrideInMonthlyMarket?: boolean
) {
	let formattedComp = clone(comp);
	formattedComp = format(comp, type, overrideInMonthlyMarket).reduce(function (
		acc: { [x: string]: any },
		item: { name: string | number; value: any }
	) {
		acc[item.name] = item.value;
		return acc;
	}, formattedComp);
	return formattedComp;
};

export type CompSectionedMapping = Record<string, FormattedComp[]>;

export const sectionedMapping = function (comp: Comp, type: AttributeType) {
	return format(comp, type).reduce<CompSectionedMapping>(function (
		attrsBySection,
		attr
	) {
		const section = attr.section;
		if (!section) {
			return attrsBySection;
		}

		if (!attrsBySection[section]) {
			attrsBySection[section] = [];
		}
		attrsBySection[section].push(attr);

		return attrsBySection;
	}, {});
};

export const getDateFormat = (locale: Locale = 'en_US') => {
	if (locale === 'en_US') {
		return 'MM/DD/YYYY';
	} else {
		return 'DD/MM/YYYY';
	}
};
