export const formatAmps = (amps: string) => {
	if (amps.includes('/')) {
		const [start, end] = amps.split('/');
		const startValue = start.endsWith('A') ? start : `${start}A`;
		const endValue = end.endsWith('A') ? end : `${end}A`;

		return `${startValue}—${endValue}`;
	}

	return amps.endsWith('A') ? amps : `${amps}A`;
};
