import { defaultTheme } from '@compstak/ui-kit';
import { SortDirection } from 'api';

export const HEADER_INNER_CELL_HEIGHT = 34;
export const ACTION_COLUMN_WIDTH_SMALL = 134;
export const ACTION_COLUMN_WIDTH_LARGE = 214;
export const ACTION_COLUMN_BREAKPOINT = 1500;
export const ROW_HEIGHT = 45;
export const CHECKBOX_CELL_SIZE = 34;
export const LOADING_ROW_TYPE = 'loading_row';
export const LOAD_MORE_THRESHOLD = 100;
export const DEFAULT_COLUMN_WIDTH = 100;
export const MIN_COLUMN_WIDTH = 100;
export const DEFAULT_SORT_DIRECTION: SortDirection = 'asc';
export const DEFAULT_SCROLLBAR_WIDTH = 16;

export const COLUMN_PREF_MAP = {
	lease: 'columnPreferences',
	sale: 'salesColumnPreferences',
	property: 'propertyColumnPreferences',
} as const;

// TODO: Add to the theme ?
export const SELECTED_ROW_BG = '#f2fcff';
export const HOVERED_ROW_BG = defaultTheme.colors.neutral.n20;

// Comps With Limited Details Colors
// TODO: Add colors to ui-kit
export const COMP_WITH_LIMITED_DETAILS_ROW_BG = '#FEEEC3';
export const SELECTED_COMP_WITH_LIMITED_DETAILS_ROW_BG = '#FDDD87';
export const HOVERED_COMP_WITH_LIMITED_DETAILS_ROW_BG = '#FEE8AF';
