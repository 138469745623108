import { useEffect, useMemo } from 'react';
import {
	ChildrenProps,
	FilterBase,
} from 'Components/Filters/Base/Filter/FilterBase';
import { FilterDescription } from 'Components/Filters/Base/Filter/FilterDescription';
import { useFilterFieldContext } from '../FilterFieldContextProvider';
import { LeaseFilters } from 'models/filters/lease';
import { ChoiceSwitcher } from 'Components/Filters/Base/ChoiceSwitcher';

const LIMITED_DETAILS_ATTRIBUTES: Record<
	keyof Pick<LeaseFilters, 'hasFullOrLimitedDetails' | 'hasLimitedDetails'>,
	string
> = {
	hasFullOrLimitedDetails: 'Full and Limited Detail Comps',
	hasLimitedDetails: 'Only Limited Detail Comps',
} as const;

export const LimitedDetailsFilter = () => {
	const { filters } = useFilterFieldContext();

	const attribute = useMemo(
		() =>
			Object.typedKeys(LIMITED_DETAILS_ATTRIBUTES).find(
				(attr) => filters[attr]
			) ?? 'hasFullOrLimitedDetails',
		[filters]
	);

	return (
		<FilterBase attribute={attribute}>
			{(props) => <LimitedDetailsFilterContent {...props} />}
		</FilterBase>
	);
};

const LimitedDetailsFilterContent = (
	props: ChildrenProps<'hasFullOrLimitedDetails' | 'hasLimitedDetails'>
) => {
	const filters = props.filters;

	// set default option if no relevant filters are set
	useEffect(() => {
		if (!filters.hasFullOrLimitedDetails && !filters.hasLimitedDetails) {
			props.onFilterChange({
				hasFullOrLimitedDetails: 'true',
			});
		}
	}, []);

	return (
		<>
			<ChoiceSwitcher
				{...props}
				attribute={'hasFullOrLimitedDetails'}
				filter={filters.hasFullOrLimitedDetails}
				options={[
					{
						title: LIMITED_DETAILS_ATTRIBUTES['hasFullOrLimitedDetails'],
						value: 'true',
					},
				]}
				onFilterChange={(changes) => {
					props.onFilterChange({
						...changes,
						hasLimitedDetails: null,
					});
				}}
			/>
			<ChoiceSwitcher
				{...props}
				attribute={'hasLimitedDetails'}
				filter={filters.hasLimitedDetails}
				options={[
					{
						title: LIMITED_DETAILS_ATTRIBUTES['hasLimitedDetails'],
						value: 'true',
					},
				]}
				onFilterChange={(changes) => {
					props.onFilterChange({
						...changes,
						hasFullOrLimitedDetails: null,
					});
				}}
			/>
			<FilterDescription>
				Limited Detail Comps are missing one of the required fields: Tenant
				Name, Space Type, Transaction SqFt, Rent Info, Term info
			</FilterDescription>
		</>
	);
};
