import {
	CompSectionedMapping,
	FormattedComp,
	formatCompAttribute,
} from 'util/comp-format/src/format';
import CompSection from './CompSection';
import { LeaseComp } from 'types';
import { usePropertyByIdQuery } from 'api';
import { Flex, Spinner } from '@compstak/ui-kit';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemo } from 'react';
import { list } from 'util/comp-format/attributes';
import {
	createSpaceAndBuildingSections,
	spaceAndBuildingCommonAttrs,
} from './CompSections.utils';
import styled from 'styled-components';

type CompSectionsProps = {
	sections: string[];
	comp: LeaseComp;
	sectionedAttrs: CompSectionedMapping;
};

export const CompSections = ({
	sections,
	comp,
	sectionedAttrs,
}: CompSectionsProps) => {
	const { updatedLeaseDetailView: updatedLeaseDetailViewFF } =
		useFeatureFlags();
	const {
		data: property,
		isLoading: isPropertyLoading,
		error: propertyError,
	} = usePropertyByIdQuery(
		{ id: comp.propertyId },
		{ enabled: updatedLeaseDetailViewFF, refetchOnWindowFocus: false }
	);

	// In case if given property attributes are not equal to comp attributes
	// we need to add those attributes to Space section
	// and delete those attributes from Building section
	const reOrderedAttributes = useMemo(() => {
		if (!property || propertyError) {
			return sectionedAttrs;
		}

		const { spaceAttributes, buildingAttributes } =
			createSpaceAndBuildingSections({
				comp,
				property,
			});

		const attributesSettings = list('lease');

		const additionalSpaceAttributes = spaceAttributes
			.map((spaceAttribute) => {
				const attributeSettings = attributesSettings.find(
					(attributeSetting) => attributeSetting.name === spaceAttribute.name
				);

				if (!attributeSettings) return null;

				const compAttribute = formatCompAttribute({
					comp,
					compType: 'lease',
					attributeSettings,
				});

				return {
					...compAttribute,
					value: spaceAttribute.value,
				};
			})
			.filter(Boolean) as FormattedComp[];

		const additionalBuildingAttributes = buildingAttributes
			.map((buildingAttribute) => {
				const attributeSettings = attributesSettings.find(
					(attributeSetting) => attributeSetting.name === buildingAttribute.name
				);

				if (!attributeSettings) return null;

				const compAttribute = formatCompAttribute({
					comp,
					compType: 'lease',
					attributeSettings,
				});

				return {
					...compAttribute,
					value: buildingAttribute.value,
				};
			})
			.filter(Boolean) as FormattedComp[];

		const spaceAttributesWithoutLeaseAttributes = sectionedAttrs.Space.filter(
			(item) => !spaceAndBuildingCommonAttrs.find((name) => name === item.name)
		);

		const buildingAttributesWithoutLeaseAttributes =
			sectionedAttrs.Building.filter(
				(item) =>
					!spaceAndBuildingCommonAttrs.find((name) => name === item.name)
			);

		const spaceSectionAttributes = [
			...spaceAttributesWithoutLeaseAttributes,
			...additionalSpaceAttributes,
		];
		const buildingSectionAttributes = [
			...buildingAttributesWithoutLeaseAttributes,
			...additionalBuildingAttributes,
		];

		return {
			...sectionedAttrs,
			Space: spaceSectionAttributes,
			Building: buildingSectionAttributes,
		};
	}, [sectionedAttrs, property, comp, propertyError]);

	if (isPropertyLoading && updatedLeaseDetailViewFF) {
		return (
			<Flex justifyContent="center">
				<Spinner />
			</Flex>
		);
	}

	return (
		<SectionsContainer>
			{sections.map((sectionName) => {
				return (
					<CompSection
						key={sectionName}
						section={sectionName}
						comp={comp}
						sectionedAttrs={reOrderedAttributes}
					/>
				);
			})}
		</SectionsContainer>
	);
};

const SectionsContainer = styled.div`
	column-width: 290px;
	column-gap: 25px;
`;
