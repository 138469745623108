import { useCallback } from 'react';
import { LeaseComp } from 'types';

const requiredAttributes: (keyof LeaseComp)[] = [
	'tenantName',
	'transactionSize',
	'startingRent',
	'executionDate',
	'commencementDate',
];

export const useCheckRequiredAttributes = () => {
	const checkRequiredAttributes = useCallback(
		(leaseComp: LeaseComp, attribute: keyof LeaseComp) => {
			return Boolean(
				requiredAttributes.includes(attribute) &&
					!leaseComp[attribute as keyof LeaseComp] &&
					leaseComp?.hasLimitedDetails
			);
		},
		[]
	);

	return {
		checkRequiredAttributes,
	};
};
