import { Flex } from '@compstak/ui-kit';
import IconAlert from 'ui/svg_icons/triangle_alert.svg';
import styled from 'styled-components';

export const UndisclosedValue = () => {
	return (
		<Flex justifyContent="flex-end">
			<span>
				<StyledIconAlert />
			</span>
			<StyledText>Undisclosed</StyledText>
		</Flex>
	);
};

const StyledIconAlert = styled(IconAlert)`
	height: 0.75rem;
	margin-right: 0.25rem;
`;

const StyledText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	font-style: italic;
`;
