import React, { Component } from 'react';

import Table from './Table';
import NoteItem from './NoteItem';

import Editable from '../Editable';
import Hideable from '../Hideable';
import HideableControl from '../Hideable/HideableControl';
import {
	SaleCompAddress,
	LeaseCompAddress,
} from 'Pages/Export/Components/ExportPDFLayout';
import Logo from '../../svg_icons/cs_full_logo.svg';
import { ExportDataState } from 'reducers/export';
import { CompType, SalesComp, LeaseComp } from 'types/comp';
import { Feature, Point, point } from '@turf/helpers';
import styled from 'styled-components';
import './lineItemLandscape.nomodule.css';
import { ExportActions } from 'actions/export';
import { ExportPDFPage } from 'Pages/Export/Components/UI';
import { MapImageWrapper } from 'maps/components/MapWrapper/MapImageWrapper';

type CompsAddresses =
	| Map<string, SaleCompAddress>
	| Map<string, LeaseCompAddress>;

export interface LineItemLandscapeProps {
	editable: boolean;
	logoPickers: unknown;
	compType: CompType;
	disclaimerText: string;
	exportData: ExportDataState;
	compsAddresses: CompsAddresses;
	formattedComps: LeaseComp[] | SalesComp[];
	mapWidth: number;
	mapHeight: number;
	exportActions: ExportActions;
}

export default class LineItemLandscape extends Component<LineItemLandscapeProps> {
	constructor(props: LineItemLandscapeProps) {
		super(props);
		this.state = {};
	}

	// @ts-expect-error TS7006: Parameter 'key' implicitly has...
	logoPickerOrNothing(key) {
		if (
			this.props.editable &&
			// @ts-expect-error TS2571: Object is of type 'unknown'....
			this.props.logoPickers[key] &&
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			!this.props.exportData[key]
		) {
			// @ts-expect-error ts-migrate(2339) FIXME: Property 'logoPickers' does not exist on type 'Rea... Remove this comment to see the full error message
			return this.props.logoPickers[key];
		}
		return null;
	}

	// @ts-expect-error TS7006: Parameter 'key' implicitly has...
	logoOrNothing(key) {
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (this.props.editable && this.props.exportData[key]) {
			// @ts-expect-error TS2571: Object is of type 'unknown'....
			return this.props.logoPickers[key];
		}
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		if (this.props.exportData[key]) {
			// @ts-expect-error TS7053: Element implicitly has an 'any...
			return <img style={{ width: '60px' }} src={this.props.exportData[key]} />;
		}
		return null;
	}

	renderMap() {
		const pinsForDisplaying = Array.from(
			this.props.compsAddresses.values()
		).slice(0, PINS_LIMIT);

		const locations: Feature<Point>[] = [];

		pinsForDisplaying.forEach((address) =>
			locations.push(point([address.lon, address.lat]))
		);

		return (
			<MapImageContainer>
				<MapImageWrapper
					width={this.props.mapWidth}
					height={this.props.mapHeight}
					position={{
						type: 'bounds',
						points: locations,
					}}
					pinPoints={locations}
					getPinLabel={(_, i) => {
						return (i + 1).toString();
					}}
					mapDomain="export"
				/>
				<MapImageInfo>
					The map shows only the first {PINS_LIMIT} pins.
				</MapImageInfo>
			</MapImageContainer>
		);
	}

	render() {
		const hideableProps = this.props.editable
			? {
					hiddenFields: this.props.exportData.hiddenFields,
					hideCustomExportField: this.props.exportActions.hideCustomExportField,
					showCustomExportField: this.props.exportActions.showCustomExportField,
				}
			: {};

		return (
			<ExportPDFPage className="page">
				<header>
					<div className="left">
						{this.props.editable ? (
							// @ts-expect-error TS2322: Type '{ children: string | und...
							<Editable
								name="title"
								updateValue={
									this.props.exportActions.updateCustomExportMetaData
								}
								className="inline"
							>
								{this.props.exportData.title}
							</Editable>
						) : (
							<span>{this.props.exportData.title}</span>
						)}
					</div>
					<div className="right">
						{this.logoPickerOrNothing('companyLogo')}
						{this.logoOrNothing('companyLogo')}
					</div>
				</header>
				<div className="intro-block">
					{this.renderMap()}
					<div className="notes">
						<ul>
							<NoteItem
								hiddenFields={this.props.exportData.hiddenFields}
								editable={this.props.editable}
								hasValue={!!this.props.exportData.dateCreated}
								field="createdOn"
							>
								<div className="label">
									{this.props.editable ? (
										<HideableControl
											// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
											html="Created On"
											target="createdOn"
											{...hideableProps}
										/>
									) : (
										'Created On'
									)}
								</div>
								{this.props.editable ? (
									// @ts-expect-error TS2769: No overload matches this call....
									<Hideable
										hideableKey="createdOn"
										hiddenFields={this.props.exportData.hiddenFields}
									>
										<div className="data">
											{this.props.exportData.dateCreated}
										</div>
									</Hideable>
								) : (
									<div className="data">
										{this.props.exportData.dateCreated}
									</div>
								)}
							</NoteItem>

							<NoteItem
								hiddenFields={this.props.exportData.hiddenFields}
								editable={this.props.editable}
								hasValue={
									!!this.props.exportData.preparedFor ||
									this.props.exportData.clientLogo
								}
								field="preparedFor"
							>
								<div className="label">
									{this.props.editable ? (
										<HideableControl
											// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
											html="Prepared For"
											target="preparedFor"
											{...hideableProps}
										/>
									) : (
										'Prepared For'
									)}
								</div>

								{this.props.editable ? (
									<div className="data contact">
										{/* @ts-expect-error TS2769: No overload matches this call.... */}
										<Hideable
											hideableKey="preparedFor"
											hiddenFields={this.props.exportData.hiddenFields}
										>
											{this.logoOrNothing('clientLogo')}
											{/* @ts-expect-error TS2322: Type '{ children: string | und... */}
											<Editable
												className="block"
												multiline={true}
												name="preparedFor"
												updateValue={
													this.props.exportActions.updateCustomExportMetaData
												}
												placeholder="e.g. client's name"
												useEmptyClass={true}
											>
												{this.props.exportData.preparedFor}
											</Editable>
											{this.logoPickerOrNothing('clientLogo')}
										</Hideable>
									</div>
								) : (
									<div className="data contact">
										<div
											className="block"
											dangerouslySetInnerHTML={{
												// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportData' does not exist on type 'Read... Remove this comment to see the full error message
												__html: this.props.exportData.preparedFor,
											}}
										/>
										{this.logoOrNothing('clientLogo')}
									</div>
								)}
							</NoteItem>

							<NoteItem
								hiddenFields={this.props.exportData.hiddenFields}
								editable={this.props.editable}
								hasValue={!!this.props.exportData.preparedBy}
								field="preparedBy"
							>
								<div className="label">
									{this.props.editable ? (
										<HideableControl
											// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
											html="Prepared By"
											target="preparedBy"
											{...hideableProps}
										/>
									) : (
										'Prepared By'
									)}
								</div>
								<div className="data contact">
									{this.props.editable ? (
										// @ts-expect-error TS2769: No overload matches this call....
										<Hideable
											hideableKey="preparedBy"
											hiddenFields={this.props.exportData.hiddenFields}
										>
											{/* @ts-expect-error TS2322: Type '{ children: string | und... */}
											<Editable
												className="block"
												multiline={true}
												name="preparedBy"
												updateValue={
													this.props.exportActions.updateCustomExportMetaData
												}
											>
												{this.props.exportData.preparedBy}
											</Editable>
										</Hideable>
									) : (
										<div
											className="block"
											dangerouslySetInnerHTML={{
												// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportData' does not exist on type 'Read... Remove this comment to see the full error message
												__html: this.props.exportData.preparedBy,
											}}
										/>
									)}
								</div>
							</NoteItem>

							<NoteItem
								hiddenFields={this.props.exportData.hiddenFields}
								editable={this.props.editable}
								hasValue={!!this.props.exportActions.updateCustomExportMetaData}
								field="notes"
							>
								<div className="label">
									{this.props.editable ? (
										<HideableControl
											// @ts-expect-error ts-migrate(2769) FIXME: Property 'html' does not exist on type 'IntrinsicA... Remove this comment to see the full error message
											html="Notes"
											target="notes"
											{...hideableProps}
										/>
									) : (
										'Notes'
									)}
								</div>
								<div className="data">
									{this.props.editable ? (
										// @ts-expect-error TS2769: No overload matches this call....
										<Hideable
											hideableKey="notes"
											hiddenFields={this.props.exportData.hiddenFields}
										>
											{/* @ts-expect-error TS2322: Type '{ children: string | und... */}
											<Editable
												className="block pad"
												multiline={true}
												name="notes"
												updateValue={
													this.props.exportActions.updateCustomExportMetaData
												}
												placeholder="Enter your comments..."
												useEmptyClass={true}
											>
												{this.props.exportData.notes}
											</Editable>
										</Hideable>
									) : (
										<div
											className="block pad"
											dangerouslySetInnerHTML={{
												// @ts-expect-error ts-migrate(2339) FIXME: Property 'exportData' does not exist on type 'Read... Remove this comment to see the full error message
												__html: this.props.exportData.notes,
											}}
										/>
									)}
								</div>
							</NoteItem>
						</ul>
					</div>
				</div>
				<div className="table-container">
					{/* table */}
					<Table
						editable={this.props.editable}
						compsAddresses={this.props.compsAddresses}
						exportActions={this.props.exportActions}
						hiddenFields={this.props.exportData.hiddenFields}
						compType={this.props.compType}
						comps={this.props.formattedComps}
					/>
				</div>
				<footer>
					<span className="text">Data provided by</span>
					<a href="http://compstak.com/">
						<Logo height={20} />
					</a>
					<div className="disclaimer-text">{this.props.disclaimerText}</div>
				</footer>
			</ExportPDFPage>
		);
	}
}

const MapImageInfo = styled.span`
	font-size: 10px;
`;
const MapImageContainer = styled.div`
	width: 45%;
	min-height: 240px;
	display: inline-block;
	> img,
	> div {
		width: 100%;
	}
`;

const PINS_LIMIT = 99;
