import { Market } from '@compstak/common';
import { radiusExpanderSearch } from 'actions/search';
import { ResultsButton } from 'Components/ResultsButton';
import { useChangeMarkets } from 'hooks/useChangeMarkets';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { createFilters } from 'models/filters';
import { FiltersObject } from 'models/filters/types';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { isListFilterKey } from 'models/filters/util/isListFilterKey';
import { filtersToQueryString } from 'models/filters/util/urls';
import {
	getSuggestionItemTitle,
	isGlobalSuggestionItem,
	isPropertySubtypeSuggestionItem,
	isPropertyTypeSuggestionItem,
	isSpaceSubtypeSuggestionItem,
	isSpaceTypeSuggestionItem,
} from 'Pages/Home/Components/SearchBar/Suggestions/helpers';
import { focusProperty } from 'Pages/Search/Map/actions';
import { useMultiSelect } from 'Pages/Search/MultiSelectProvider';
import { useDispatch } from 'react-redux';

import { useReferenceDataQuery } from 'api';
import {
	PlaceSuggestionItem,
	Suggestion,
	SuggestionItem,
} from 'api/suggestions/suggestions';
import Stats from 'Components/Stats';
import { useMarkets } from 'hooks/useMarkets';
import { getSetFilterKeysExceptMarkets } from 'models/filters/util/filterHelpers';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import MultiSelectOptionsButton from 'MultiSelectMode/Singletons/MultiSelectOptionsButton';
import { SearchBar } from 'Pages/Home/Components/SearchBar/SearchBar';
import { useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import { SEARCH_BY_VIEW_ROUTES, useNavigate } from 'router';
import styled from 'styled-components';
import { CompType } from 'types';
import { useCheckMarketAccess } from 'util/marketAccessUtils';
import { StatsContainer } from './UI';

type SearchToolbarMapToolbarProps = {
	compType: CompType;
	filters: FiltersObject;
	params: SearchLayoutRouteParams;
};

export const SearchToolbarMapToolbar = ({
	compType,
	filters,
	params,
}: SearchToolbarMapToolbarProps) => {
	const isSearchBarVisible = useMemo(() => {
		return getSetFilterKeysExceptMarkets(filters).length === 0;
	}, [filters]);
	const { isMultiSelectOn } = useMultiSelect();
	const { data: referenceData } = useReferenceDataQuery();
	const markets = useMarkets();
	const [, , resetFilters] = useFilters();

	const navigate = useNavigate();
	const changeMarkets = useChangeMarkets();
	const { checkMarketAccess } = useCheckMarketAccess();
	const dispatch = useDispatch();

	const checkSelectionPermission = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem
	) => {
		return (
			!isGlobalSuggestionItem(suggestion, suggestionItem) ||
			checkMarketAccess({
				marketId: suggestionItem.marketId,
				compType: compType,
			})
		);
	};

	const onSuggestionSelection = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem
	) => {
		if (!checkSelectionPermission(suggestion, suggestionItem)) {
			return;
		}
		const suggestionItemTitle = getSuggestionItemTitle(suggestionItem);

		let suggestionFilters = createFilters(compType, {
			market: filters.market,
			markets: filters.markets,
		});

		switch (suggestion.field) {
			case 'buildingName':
			case 'buildingAddressAndCity': {
				focusBuilding(suggestion, suggestionItem);
				return;
			}
			case 'places': {
				radiusSearch(suggestionItem as PlaceSuggestionItem);
				return;
			}
			case 'submarket': {
				suggestionFilters = mergeFilters(suggestionFilters, {
					submarket: [suggestionItemTitle],
				});
				resetFilters(suggestionFilters);
				return;
			}
			case 'propertyTypeOrSubtype':
			case 'spaceTypeOrPropertySubtype':
			case 'spaceTypeOrSpaceSubtype': {
				if (isSpaceTypeSuggestionItem(suggestionItem)) {
					const spaceType = referenceData.spaceTypes.find(
						(type) => type.id === suggestionItem.spaceTypeId
					);
					if (spaceType) {
						suggestionFilters = mergeFilters(suggestionFilters, {
							spaceTypeId: [spaceType.id],
						});
					}
				} else if (isSpaceSubtypeSuggestionItem(suggestionItem)) {
					const spaceSubtype = referenceData.spaceSubtypes.find(
						(type) => type.id === suggestionItem.spaceSubtypeId
					);
					if (spaceSubtype) {
						suggestionFilters = mergeFilters(suggestionFilters, {
							spaceSubtypeId: [spaceSubtype.id],
						});
					}
				} else if (isPropertyTypeSuggestionItem(suggestionItem)) {
					const buildingPropertyType = referenceData.propertyTypes.find(
						(type) => type.id === suggestionItem.propertyTypeId
					);
					if (buildingPropertyType) {
						suggestionFilters = mergeFilters(suggestionFilters, {
							buildingPropertyTypeId: [buildingPropertyType.id],
						});
					}
				} else if (isPropertySubtypeSuggestionItem(suggestionItem)) {
					const buildingPropertySubtype = referenceData.propertySubtypes.find(
						(subtype) => subtype.id === suggestionItem.propertySubtypeId
					);
					if (buildingPropertySubtype) {
						suggestionFilters = mergeFilters(suggestionFilters, {
							buildingPropertySubtype: [buildingPropertySubtype.name],
						});
					}
				}

				navigate(
					`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'list' })}?${filtersToQueryString(suggestionFilters)}`
				);
				return;
			}
			default: {
				const suggestionFilter = {
					[suggestion.field]: isListFilterKey(suggestion.field)
						? [suggestionItemTitle]
						: suggestionItemTitle,
				};
				suggestionFilters = mergeFilters(suggestionFilters, suggestionFilter);
				navigate(
					`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: 'list' })}?${filtersToQueryString(suggestionFilters)}`
				);
				return;
			}
		}
	};

	const radiusSearch = (suggestionItem: PlaceSuggestionItem) => {
		dispatch(
			radiusExpanderSearch({
				compType,
				lat: suggestionItem.lat,
				lon: suggestionItem.lon,
				filters,
			})
		);
	};

	const focusBuilding = (
		suggestion: Suggestion,
		suggestionItem: SuggestionItem
	) => {
		const market = markets[suggestionItem.marketId];

		if (isGlobalSuggestionItem(suggestion, suggestionItem)) {
			handleGlobalSuggestionMarket(market);
		}

		dispatch(
			focusProperty({
				marketId: market.id,
				propertyAddress: getSuggestionItemTitle(suggestionItem),
			})
		);
	};

	const handleGlobalSuggestionMarket = (market: Market) => {
		const currentMarkets = getFiltersMarkets(filters);

		if (currentMarkets.some((currMarket) => currMarket.id === market.id)) {
			return;
		}

		changeMarkets([...currentMarkets, market]);
	};

	return (
		<SearchToolbarContainer>
			{isMultiSelectOn ? (
				<MultiSelectOptionsButton compType={compType} />
			) : isSearchBarVisible ? (
				<>
					<SearchBar
						key={compType}
						filters={filters}
						compType={compType}
						onConfirmSelection={onSuggestionSelection}
					/>
					<ResultsButton compType={compType} filters={filters} />
				</>
			) : (
				<StatsContainer>
					<div>
						<Stats compType={compType} filters={filters} view={params.view} />
					</div>
					<div>
						<ResultsButton compType={compType} filters={filters}/>
					</div>
				</StatsContainer>
			)}
		</SearchToolbarContainer>
	);
};

const SearchToolbarContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 20px;
	gap: 1.25rem;
`;
