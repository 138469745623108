import styled from 'styled-components';
import { colors, Button, MQB } from '@compstak/ui-kit';
import { emailConstants } from '@compstak/common';
import { useMediaQuery } from 'react-responsive';

const imagePathHeader =
	'/src/PortfolioAnalytics/Upsell/portfolio-upsell-header-image.png';

const contentItems = [
	{
		image: '/src/PortfolioAnalytics/Upsell/customizable-portfolios.png',
		title: 'Customizable Portfolios',
		description:
			'Tailor your insights by focusing on specific properties that align with your investment strategies',
	},
	{
		image: '/src/PortfolioAnalytics/Upsell/real-time-insights.png',
		title: 'Real-time Lease Insights',
		description:
			'Improve investment decision making with access to aggregated metrics such as average current rent, average free rent and TI, average lease terms, and more',
	},
	{
		image: '/src/PortfolioAnalytics/Upsell/performance-benchmarking.png',
		title: 'Performance Benchmarking',
		description:
			"Understand your relative position in the market or submarket by comparing a building or a portfolio's performance with other portfolios",
	},
	{
		image: '/src/PortfolioAnalytics/Upsell/powerful-analytics.png',
		title: 'Powerful Analytics',
		description:
			'Capitalize on investment opportunities and address performance vulnerabilities with real-time alerts on your portfolio and your competitive portfolios',
	},
	{
		image: '/src/PortfolioAnalytics/Upsell/actionable-alerts.png',
		title: 'Actionable Alerts',
		description:
			'Capitalize on investment opportunities and address performance vulnerabilities with real-time alerts on your portfolio and your competitive portfolios',
	},
];

export const PortfolioUpsellView = () => {
	const isMin834Px = useMediaQuery({ minWidth: MQB.T_834 });
	const isMin1280Px = useMediaQuery({ minWidth: MQB.D_1280 });

	return (
		<>
			<UpsellHeader isMin834Px={isMin834Px} isMin1280Px={isMin1280Px}>
				<UpsellHeaderLeft isMin834Px={isMin834Px}>
					<UpsellHeaderTitle>
						Get access to <span>Portfolios</span>
					</UpsellHeaderTitle>
					<UpsellHeaderDescription>
						Build custom portfolios and access critical leasing insights based
						on CompStak's reliable, granular data.
					</UpsellHeaderDescription>
					<UpsellHeaderButton
						size="l"
						href={`mailto:${emailConstants.clientSuccess}`}
					>
						Contact Your Rep
					</UpsellHeaderButton>
				</UpsellHeaderLeft>
				<UpsellHeaderImage src={imagePathHeader} isMin834Px={isMin834Px} />
			</UpsellHeader>
			<Content isMin834Px={isMin834Px} isMin1280Px={isMin1280Px}>
				{contentItems.map((contentItem, index) => (
					<ContentAndImageContainer key={index}>
						<ContentImage src={contentItem.image} />
						<ContentText>
							<ContentTextTitle>{contentItem.title}</ContentTextTitle>
							<ContentTextDescription>
								{contentItem.description}
							</ContentTextDescription>
						</ContentText>
					</ContentAndImageContainer>
				))}
			</Content>
		</>
	);
};

const UpsellHeader = styled.div<{
	isMin834Px: boolean;
	isMin1280Px: boolean;
}>`
	background-color: #151342;
	display: flex;
	padding: ${({ isMin834Px: is834 }) => (is834 ? '0px 80px' : '80px')};
	flex-direction: ${({ isMin834Px: is834 }) => (is834 ? 'row' : 'column')};
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	align-self: stretch;
	height: ${({ isMin834Px: is834 }) => (is834 ? '497px' : 'auto')};
	color: ${colors.white.white};
`;

const UpsellHeaderLeft = styled.div<{ isMin834Px: boolean }>`
	display: flex;
	flex-direction: column;
	max-width: 548px;
	align-items: ${({ isMin834Px: is834 }) => (is834 ? 'flex-start' : 'center')};
	text-align: ${({ isMin834Px: is834 }) => (is834 ? 'left' : 'center')};
	gap: 1.5rem;
	flex: 1 0 0;
	order: ${({ isMin834Px: is834 }) => (is834 ? 0 : 1)};
`;

const UpsellHeaderTitle = styled.div`
	font-size: 2.5rem;
	font-style: normal;
	font-weight: 325;
	line-height: 4rem;
	letter-spacing: -0.4px;
	span {
		font-weight: 400;
	}
`;

const UpsellHeaderDescription = styled.div`
	font-size: 1.25rem;
	font-weight: 350;
	line-height: 2rem;
	letter-spacing: 0px;
	text-align: inherit;
`;

const UpsellHeaderButton = styled(Button)`
	margin-top: 40px;
	min-width: 48px;
	min-height: 72px;
	padding: 16px 24px;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: 2.5rem;
	letter-spacing: 0px;
`;

const UpsellHeaderImage = styled.img<{ isMin834Px: boolean }>`
	padding: ${({ isMin834Px: is834 }) => (is834 ? '36px 0' : '0')};
	width: ${({ isMin834Px: is834 }) => (is834 ? '48%' : '80%')};
	max-width: 548px;
`;

const ContentAndImageContainer = styled.div`
	display: flex;
	gap: 2.5rem;
	max-width: 1200px;
	align-items: center;
	justify-content: center;
	align-self: center;
`;

const ContentImage = styled.img`
	padding: 14px 0;
`;

const ContentText = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 1.5rem;
	justify-content: center;
	max-width: 500px;
`;

const ContentTextTitle = styled.div`
	font-size: 2.3em;
	font-weight: 450;
	line-height: 2rem;
	letter-spacing: -0.4px;
`;

const ContentTextDescription = styled.div`
	font-size: 1.7em;
	font-weight: 325;
	line-height: 2.3rem;
	letter-spacing: 0.16px;
`;

const Content = styled.div<{ isMin834Px: boolean; isMin1280Px: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${colors.white.white};
	${ContentAndImageContainer} {
		padding: ${({ isMin834Px: is834, isMin1280Px: is1280 }) =>
			is1280 ? '40px 0' : is834 ? '40px 60px' : '40px'};
		max-height: ${({ isMin1280Px: is1280 }) => (is1280 ? '400px' : 'none')};
		flex-direction: ${({ isMin834Px: is834 }) => (is834 ? 'row' : 'column')};
		&:nth-child(even) {
			flex-direction: ${({ isMin834Px: is834 }) =>
				is834 ? 'row-reverse' : 'column'};
		}
	}
	& ${ContentImage} {
		max-height: ${({ isMin834Px: is834, isMin1280Px: is1280 }) =>
			is1280 ? '370px' : is834 ? '300px' : '370px'};
	}
`;
