import { MapInteractivity, MapRef, MapStyle } from '@compstak/maps';
import { PortfolioV2 } from 'api';
import {
	MapButtonsContainer,
	MapSpinner,
	MapStyleButton,
	MapCenteredContainer,
} from 'maps';
import { useMemo, useState } from 'react';
import { CenterOnMarketsEffect } from 'Pages/Search/Map/Components/MapEffects/CenterOnMarketsEffect';
import { useMarkets } from 'hooks/useMarkets';
import { PortfolioMVTLayer } from './PortfolioMVTLayer';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';
import styled from 'styled-components';
import {
	BENCHMARKING_COLOR_MAP,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from 'PortfolioAnalytics/Benchmarking/constants';
import { PortfoliosMapLegend } from './PortfoliosMapLegend';
import { PortfolioMVTFilters } from './types';
import { portfolioWidgetCss } from 'PortfolioAnalytics/UI';
import { MapWrapper } from 'maps/components/MapWrapper/MapWrapper';
import { usePortfolioAccessibleMarkets } from 'util/marketAccessUtils';

type PortfolioMapProps = {
	portfolioIds: number[];
	onMapRef?: (mapRef: MapRef) => void;
	isMapView?: boolean;
};

export const PortfoliosMap = ({
	portfolioIds,
	onMapRef,
	isMapView,
}: PortfolioMapProps) => {
	const [mapStyle, setMapStyle] = useState(MapStyle.DEFAULT);
	const markets = useMarkets();
	const { filters } = usePortfolioFilters();

	const portfoliosQueries = usePortfoliosByIdsQueriesV2(
		portfolioIds.map((id) => ({ id, filters }))
	);
	const data = portfoliosQueries
		.map((query) => query.data)
		.filter((data) => data != null) as PortfolioV2[];
	const isFetching = portfoliosQueries.some((query) => query.isFetching);

	const portfoliosMarkets = useMemo(() => {
		if (isFetching) {
			return [];
		}

		return data.flatMap((portfolio) =>
			portfolio.markets.map(({ marketId }) => markets[marketId])
		);
	}, [data, isFetching, markets]);

	const filteredMarkets = usePortfolioAccessibleMarkets({
		portfolioMarkets: filters.markets,
		compType: 'property',
	});

	return (
		<Container>
			<MapWrapper
				onMapRef={onMapRef}
				interactivity={
					isMapView
						? MapInteractivity.INTERACTIVE
						: MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM
				}
				mapStyle={mapStyle}
				logoPosition="top-left"
				mapDomain="portfolios"
			>
				{isFetching && <MapSpinner />}
				<CenterOnMarketsEffect markets={portfoliosMarkets} />
				{data.map((portfolio, index) => {
					const portfolioFilters: PortfolioMVTFilters = {
						...getFiltersFromPortfolioFilters({
							...filters,
							portfolio,
							compType: 'property',
							markets: filteredMarkets,
						}),
						portfolioId: {
							value: portfolio.portfolio.id,
							...(filters.spaceTypes.length > 0 && {
								spaceTypes: filters.spaceTypes,
							}),
							...(filters.leaseExpirationsMonths && {
								leaseExpirationsMonths: filters.leaseExpirationsMonths,
							}),
						},
					};

					return (
						<PortfolioMVTLayer
							key={portfolio.portfolio.id}
							id={portfolio.portfolio.id.toString()}
							filters={portfolioFilters}
							portfolio={portfolio}
							pinColor={Object.typedKeys(BENCHMARKING_COLOR_MAP)[index]}
						/>
					);
				})}
				{portfolioIds.length === 0 && (
					<MapCenteredContainer>
						{BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE}
					</MapCenteredContainer>
				)}
				<MapButtonsContainer>
					<MapStyleButton
						mapStyle={mapStyle}
						onClick={() => {
							setMapStyle(
								mapStyle === MapStyle.DEFAULT
									? MapStyle.SATELLITE
									: MapStyle.DEFAULT
							);
						}}
					/>
				</MapButtonsContainer>
				{data.length > 0 && <PortfoliosMapLegend portfolios={data} />}
			</MapWrapper>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	min-height: 31rem;
	${portfolioWidgetCss}
`;
