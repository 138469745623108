import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { MapFilterBaseChildrenProps } from '../MapFilter/MapFilterBase';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import Typeahead from 'Components/Typeahead';
import styled from 'styled-components';
import { useSearchSingleComp } from 'hooks/useSearchSingleComp';
import { isSaleComp } from 'utils/compHelpers';
import { filterInputCSS } from 'Components/Filters/Base/Filter/FilterInput';
import { useDebouncedCallback } from 'use-debounce';
import { FILTERS_DEBOUNCE_TIMEOUT } from 'util/formConstants';
import NumberFormat from 'react-number-format';
import { trimString } from 'util/trimString';

export const RadiusFilter = ({
	compType,
	filters,
	isActive,
	onFilterChange,
}: MapFilterBaseChildrenProps) => {
	const [buildingAddressAndCity, setBuildingAddressAndCity] = useState<
		string | undefined
	>(filters.radius?.buildingAddressAndCity);
	const distanceInMi = useMemo(
		() =>
			filters.radius?.distance ? filters.radius.distance * 0.621371 : undefined,
		[filters.radius?.distance]
	);

	const { comp: compByAddress } = useSearchSingleComp(
		{
			compType,
			filters: {
				markets: getFiltersMarkets(filters),
				address: [buildingAddressAndCity!],
			},
		},
		{ enabled: isActive && !!buildingAddressAndCity }
	);

	useEffect(() => {
		if (!compByAddress || !filters.radius) {
			return;
		}

		const compGeoPoint = isSaleComp(compByAddress)
			? compByAddress.portfolio[0].geoPoint
			: compByAddress.geoPoint;
		const compBuildingAddressAndCity = isSaleComp(compByAddress)
			? compByAddress.portfolio[0].buildingAddressAndCity
			: compByAddress.buildingAddressAndCity;

		onFilterChange({
			radius: {
				...filters.radius,
				center: { lat: compGeoPoint.lat, lng: compGeoPoint.lon },
				buildingAddressAndCity: compBuildingAddressAndCity,
			},
		});
	}, [compByAddress]);

	// reset address state when either:
	// - filter is not active
	// - buildingAddressAndCity is removed (e.g. radius filter was moved on a map)
	useEffect(() => {
		if (!isActive || !filters.radius?.buildingAddressAndCity) {
			setBuildingAddressAndCity(undefined);
		}
	}, [isActive, filters.radius?.buildingAddressAndCity]);

	const [onDistanceChange] = useDebouncedCallback((distanceValue: string) => {
		const distanceInKm = Number(distanceValue) * 1.609344;

		if (isNaN(distanceInKm) || distanceInKm <= 0 || !filters.radius) {
			return;
		}

		onFilterChange({
			radius: {
				...filters.radius,
				distance: distanceInKm,
			},
		});
	}, FILTERS_DEBOUNCE_TIMEOUT);

	return (
		<RadiusFilterContainer>
			<DistanceContainer>
				<NumberFormat
					decimalScale={2}
					placeholder="Enter radius"
					value={distanceInMi}
					type="text"
					onChange={(ev: ChangeEvent<HTMLInputElement>) =>
						onDistanceChange(ev.target.value)
					}
				/>
				<span>mile radius from</span>
			</DistanceContainer>
			<Typeahead
				compType={compType}
				onChange={(ev) => {
					if (!filters.radius) {
						return;
					}

					const value = (ev as ChangeEvent<HTMLInputElement>).target.value;
					if (typeof value === 'string' && !trimString(value)) {
						onFilterChange({
							radius: {
								...filters.radius,
								buildingAddressAndCity: undefined,
							},
						});
					}
				}}
				onSelection={(value) => setBuildingAddressAndCity(value)}
				keepValueOfSelection={true}
				defaultValue={buildingAddressAndCity}
				typeaheadAttribute="buildingAddressAndCity"
				markets={getFiltersMarkets(filters)}
				placeholder="Select an address as the center"
				filters={filters}
			/>
		</RadiusFilterContainer>
	);
};

const RadiusFilterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	input {
		${filterInputCSS}
	}
`;

const DistanceContainer = styled.div`
	display: inline-grid;
	align-items: center;
	gap: 0.5rem;
	grid-template-columns: 1fr 1fr;
	font-size: 0.8125rem;
`;
