import { IconCross } from '@compstak/ui-kit';
import * as Toast from '@radix-ui/react-toast';
import { nanoid } from 'nanoid';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

type Props = {
	children: ReactNode;
};

export const ToastProvider = ({ children }: Props) => {
	const [toasts, setToasts] = useState<ToastItem[]>([]);

	const removeToast = useCallback((id: string) => {
		setToasts((s) => s.filter((t) => t.id !== id));
	}, []);

	const addToast = useCallback(
		({
			type = DEFAULT_TOAST_TYPE,
			duration = DEFAULT_TOAST_TIME,
			id = nanoid(),
			...toast
		}: AddToastArgs) => {
			const newToast = {
				...toast,
				id,
				type,
				duration,
			};
			setToasts((s) => [...s, newToast]);
			return newToast;
		},
		[]
	);

	return (
		<Toast.Provider>
			<ToastContext.Provider value={{ addToast, removeToast }}>
				{toasts.map((toast) => (
					<ToastMessage key={toast.id} toast={toast} />
				))}
				{children}
				<ToastViewport id={TOAST_VIEWPORT_ID} />
			</ToastContext.Provider>
		</Toast.Provider>
	);
};

const DEFAULT_TOAST_TIME = 5000;
const DEFAULT_TOAST_TYPE: ToastType = 'info';
export const TOAST_VIEWPORT_ID = 'TOAST_VIEWPORT_ID';

const ToastMessage = ({ toast }: { toast: ToastItem }) => {
	const { removeToast } = useToast();

	return (
		<ToastRoot
			open
			onOpenChange={(open) => {
				if (!open) {
					removeToast(toast.id);
				}
			}}
			duration={toast.duration}
			style={{
				userSelect: 'auto',
			}}
			toastType={toast.type}
		>
			<div>
				<ToastTitle>
					{typeof toast.title === 'function'
						? toast.title(toast.id)
						: toast.title}
				</ToastTitle>
				{toast.description && (
					<ToastDescription>
						{typeof toast.description === 'function'
							? toast.description(toast.id)
							: toast.description}
					</ToastDescription>
				)}
			</div>
			<div>
				<Toast.Action asChild altText="Dismiss">
					<ToastClose aria-label="Close" onClick={() => removeToast(toast.id)}>
						<IconCross fill="white" />
					</ToastClose>
				</Toast.Action>
			</div>
		</ToastRoot>
	);
};

const ToastViewport = styled(Toast.Viewport)`
	position: fixed;
	bottom: 1rem;
	right: 50%;
	transform: translateX(50%);
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal + 1};
	display: grid;
	grid-template-columns: 1fr;
	gap: 0.5rem;
	max-height: 80%;
	overflow-y: auto;
`;

const ToastRoot = styled(Toast.Root)<{ toastType: ToastType }>`
	border-radius: 0.25rem;
	background: ${(p) =>
		p.toastType === 'info'
			? p.theme.colors.blue.blue400
			: p.theme.colors.red.red500};
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 1rem;
	max-width: 640px;
	display: flex;
	align-items: center;
	gap: 1.5rem;
	justify-content: space-between;
`;

const ToastTitle = styled(Toast.Title)`
	color: ${(p) => p.theme.colors.white.white};
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
`;

export const toastDescriptionCss = css`
	color: ${(p) => p.theme.colors.white.white};
	font-size: 0.875rem;
	font-weight: 300;
	line-height: 1.5;
	margin-top: 0.5rem;
`;

const ToastDescription = styled(Toast.Description)`
	${toastDescriptionCss}
`;

export const ToastLink = styled(Link)`
	${toastDescriptionCss};
	text-decoration: underline;
	vertical-align: baseline;
	&:hover {
		color: ${(p) => p.theme.colors.white.white};
		text-decoration: underline;
	}
`;

const ToastClose = styled(Toast.Close)`
	background: none;
	border: none;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const useToast = () => {
	return useContext(ToastContext);
};

const ToastContext = createContext({} as ToastContextValue);

type ToastItem = {
	id: string;
	title: ReactNode | ((toastId: string) => ReactNode);
	description?: ReactNode | ((toastId: string) => ReactNode);
	duration: number;
	type: ToastType;
};

type ToastType = 'info' | 'error';

type ToastContextValue = {
	addToast: (args: AddToastArgs) => ToastItem;
	removeToast: (id: string) => void;
};

type AddToastArgs = Pick<ToastItem, 'title' | 'description'> &
	Partial<Omit<ToastItem, 'title' | 'description'>>;
