import { ExchangeDashboard } from 'ExchangeDashboard';
import { SearchLayout } from 'Layouts/SearchLayout';
import { ChartRepository, GraphBuilder } from 'Pages/Analytics';
import LeaseDetails, { LeaseDetailsExample } from 'Pages/LeaseDetails';
import { usePermissions } from 'Pages/Login/reducers';
import { PremierList } from 'Pages/PremierList/PremierList';
import { PromotionReviewPage } from 'Pages/Promotions/promotionReviewPage';
import { PromotionsCreditsRewardsPage } from 'Pages/PromotionsCreditsRewards';
import { PropertyPage } from 'Pages/PropertyPageV3';
import { SalesDetailsV2 } from 'Pages/SalesDetailsV2';
import { SavedSearchesLayout } from 'Pages/SavedSearches/SavedSearchesLayout';
import { SearchRedirect } from 'Pages/Search/SearchRedirect';
import { EnterpriseSettings } from 'Pages/Settings/EnterpriseSettings';
import ExchangeSettings from 'Pages/Settings/ExchangeSettings';
import StackingPlan from 'Pages/StackingPlans/Layout';
import { SurveyForCreditsPage } from 'Pages/SurveyForCredits/SurveyForCreditsPage';
import { UploadPage } from 'Pages/Upload/UploadPage';
import { ExchangeHome } from 'exchange/ExchangeHome';
import { ExchangePlusRedirectRouteHOC } from 'exchange/exchangePlus/exchangePlusHOCs';
import { useIsExchange } from 'hooks';
import { useMarkets } from 'hooks/useMarkets';
import { PortfolioView } from '../PortfolioAnalytics';
import { useShouldRedirect } from '../hooks/useShouldRedirect';
import { routeWrapper } from './migration';
import { routes } from './routes';
import { RouteComponentMap } from './types';
import { ExportPDFLayout } from 'Pages/Export/Components/ExportPDFLayout';
import { ImportDataSetLayout } from 'Pages/Analytics/Builder/Components/Modals/DataSets/ImportDataSetLayout';
import { SearchRedirectV2 } from 'Pages/Search/SearchRedirectV2';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { BenchmarkingLayout } from 'PortfolioAnalytics/Benchmarking/BenchmarkingLayout';
import { useIsPortfolioAnalyticsAccessible } from 'hooks/useIsPortfolioAnalyticsAccessible';

const PremierListRoute = (props: any) => {
	const permissions = usePermissions();

	const hasAccessToPremierList = Object.values(permissions).some(
		(p) => p && p.premierList === true
	);

	useShouldRedirect(!hasAccessToPremierList, routes.home.path);

	return <PremierList {...props} />;
};

const SearchLayoutWrapped = routeWrapper(SearchLayout);
const SearchRedirectWrapped = routeWrapper((props) => {
	const { SavedSearchImprovements } = useFeatureFlags();

	return SavedSearchImprovements ? (
		<SearchRedirectV2 {...props} />
	) : (
		<SearchRedirect {...props} />
	);
});
const SavedSearchesLayoutWrapped = routeWrapper(SavedSearchesLayout);
const LeaseDetailsWrapped = routeWrapper(LeaseDetails);
const SalesDetailsWrapped = routeWrapper(SalesDetailsV2);
const ExportPDFWrapped = routeWrapper(ExportPDFLayout);
const UploadPagePickWrapped = routeWrapper(UploadPage);

const PortfolioLayoutWrapped = () => {
	const isPortfolioAnalyticsAccessible = useIsPortfolioAnalyticsAccessible();
	const {portfolioUpsellFF} = useFeatureFlags();

	useShouldRedirect((!isPortfolioAnalyticsAccessible && !portfolioUpsellFF), routes.home.path);
	return <PortfolioView />;
};

const StackingPlanWrapped = routeWrapper(StackingPlan);
const PromotionsCreditsRewardsPageWrapped = routeWrapper(
	PromotionsCreditsRewardsPage
);
const PromotionReviewPageWrapped = routeWrapper(PromotionReviewPage);

const HomeRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();

	return isExchange ? <ExchangeHome {...props} /> : <SearchLayout {...props} />;
});

const SettingsRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();

	return isExchange ? (
		<ExchangeSettings {...props} />
	) : (
		<EnterpriseSettings {...props} />
	);
});

const ExchangeAnalytics = ExchangePlusRedirectRouteHOC(GraphBuilder);

const AnalyticsRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();
	const markets = useMarkets();

	return isExchange ? (
		<ExchangeAnalytics {...props} markets={markets} />
	) : (
		<GraphBuilder {...props} markets={markets} />
	);
});

const ExchangeDashboardWrapped =
	ExchangePlusRedirectRouteHOC(ExchangeDashboard);

const DashboardRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();

	return isExchange ? (
		<ExchangeDashboardWrapped {...props} />
	) : (
		<ExchangeDashboard {...props} />
	);
});

const ExchangeRepository = ExchangePlusRedirectRouteHOC(ChartRepository);

const AnalyticsProjectsRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();

	return isExchange ? (
		<ExchangeRepository {...props} />
	) : (
		<ChartRepository {...props} />
	);
});

const ExchangeAnalyticsImport =
	ExchangePlusRedirectRouteHOC(ImportDataSetLayout);

const AnalyticsImportRoute = routeWrapper((props) => {
	const isExchange = useIsExchange();

	return isExchange ? (
		<ExchangeAnalyticsImport {...props} />
	) : (
		<ImportDataSetLayout {...props} />
	);
});

const PortfolioBenchmarkingWrapped = () => {
	const { portfolioRevampBenchmarkingFF } = useFeatureFlags();

	useShouldRedirect(!portfolioRevampBenchmarkingFF, routes.portfolios.path);

	return <BenchmarkingLayout />;
};

export const ROUTE_COMPONENTS: RouteComponentMap = {
	searchLeasesHome: SearchLayoutWrapped,
	searchSalesHome: SearchLayoutWrapped,
	searchPropertiesHome: SearchLayoutWrapped,
	leaseById: LeaseDetailsWrapped,
	saleById: SalesDetailsWrapped,
	propertyLeaseById: LeaseDetailsWrapped,
	searchLeases: SearchRedirectWrapped,
	searchLeasesByView: SearchLayoutWrapped,
	searchSales: SearchRedirectWrapped,
	searchSalesByView: SearchLayoutWrapped,
	searchProperties: SearchRedirectWrapped,
	searchPropertiesByView: SearchLayoutWrapped,
	savedSearches: SavedSearchesLayoutWrapped,
	exportPdfLeases: ExportPDFWrapped,
	exportPdfSales: ExportPDFWrapped,
	propertyExportPdfLeases: ExportPDFWrapped,
	propertyExportPdfSales: ExportPDFWrapped,
	upload: UploadPagePickWrapped,
	uploadSection: UploadPagePickWrapped,
	home: HomeRoute,
	portfolios: PortfolioLayoutWrapped,
	portfolioByIdView: PortfolioLayoutWrapped,
	portfoliosBenchmarking: PortfolioBenchmarkingWrapped,
	premierList: routeWrapper(PremierListRoute),
	settingsPage: SettingsRoute,
	propertyById: PropertyPage,
	propertyByMarketAndAddress: PropertyPage,
	analytics: AnalyticsRoute,
	analyticsDashboard: DashboardRoute,
	analyticsProjects: AnalyticsProjectsRoute,
	analyticsProjectById: AnalyticsProjectsRoute,
	analyticsImport: AnalyticsImportRoute,
	firstComp: routeWrapper(LeaseDetailsExample),
	stackingPlan: StackingPlanWrapped,
	stackingPlanByDisplayMode: StackingPlanWrapped,
	promotions: PromotionsCreditsRewardsPageWrapped,
	promotionsReview: PromotionReviewPageWrapped,
	credits: PromotionsCreditsRewardsPageWrapped,
	rewards: PromotionsCreditsRewardsPageWrapped,
	survey: routeWrapper(SurveyForCreditsPage),
};
