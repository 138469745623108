import { nullValueText } from '@compstak/common';
import { formatCompField } from 'format';
import { LeaseComp, PropertyComp } from 'types';

export const spaceAndBuildingCommonAttrs = [
	'amps',
	'volts',
	'buildingCeilingHeight',
	'buildingLoadingDocksCount',
	'buildingGroundLvlDoorsCount',
	'buildingSprinkler',
] as const;

type SpaceAndBuildingCommonAttrsNames =
	(typeof spaceAndBuildingCommonAttrs)[number];

export const leaseToPropertyAttributes: Record<
	SpaceAndBuildingCommonAttrsNames,
	Extract<
		keyof PropertyComp,
		| 'amps'
		| 'volts'
		| 'ceilingHeight'
		| 'loadingDocksCount'
		| 'groundLvlDoorsCount'
		| 'sprinkler'
	>
> = {
	amps: 'amps',
	volts: 'volts',
	buildingCeilingHeight: 'ceilingHeight',
	buildingLoadingDocksCount: 'loadingDocksCount',
	buildingGroundLvlDoorsCount: 'groundLvlDoorsCount',
	buildingSprinkler: 'sprinkler',
};

export const transformLeaseToPropertyAttribute = (attributeName: string) => {
	if (attributeName in leaseToPropertyAttributes) {
		return leaseToPropertyAttributes[
			attributeName as keyof typeof leaseToPropertyAttributes
		];
	}

	return attributeName;
};

type CompareLeaseAndBuildingAttributesProps = {
	comp: LeaseComp;
	property: PropertyComp;
};

type DiffAttribute = {
	name: string;
	value: string | number | boolean | undefined;
};

export const createSpaceAndBuildingSections = ({
	comp,
	property,
}: CompareLeaseAndBuildingAttributesProps) => {
	const spaceAttributes: Array<DiffAttribute> = [];
	const buildingAttributes: Array<DiffAttribute> = [];

	for (const attr of spaceAndBuildingCommonAttrs) {
		const propertyAttrName = leaseToPropertyAttributes[attr];
		const rawValue = comp[attr];
		const leaseValue =
			rawValue !== nullValueText
				? // @ts-expect-error Type 'LeaseComp' is missing the following properties
					formatCompField({ name: attr, row: comp })
				: undefined;
		const propertyValue =
			// @ts-expect-error Type 'PropertyComp' is missing the following properties
			formatCompField({ name: propertyAttrName, row: property });

		if (leaseValue !== undefined && leaseValue !== nullValueText) {
			spaceAttributes.push({
				name: attr,
				value: leaseValue,
			});
		}

		if (propertyValue !== undefined && propertyValue !== nullValueText) {
			// if lease attribute value is greater than property attribute value,
			// then we don't need to show property value in Building section
			if (attributeNamesToCompare.includes(attr)) {
				if (!isLeaseValueGreaterThanPropertyValue(leaseValue, propertyValue)) {
					buildingAttributes.push({
						name: attr,
						value: propertyValue,
					});
				}
			} else {
				buildingAttributes.push({
					name: attr,
					value: propertyValue,
				});
			}
		}
	}

	return { spaceAttributes, buildingAttributes };
};

const attributeNamesToCompare = [
	'buildingCeilingHeight',
	'buildingLoadingDocksCount',
	'buildingGroundLvlDoorsCount',
];

export const isLeaseValueGreaterThanPropertyValue = (
	leaseValue: string | number | boolean | undefined,
	propertyValue: string | number | boolean | undefined
) => {
	if (typeof leaseValue === 'boolean' || typeof propertyValue === 'boolean') {
		return false;
	}

	if (!leaseValue || !propertyValue) {
		return false;
	}

	const leaseNumericValue =
		typeof leaseValue === 'string' ? parseFloat(leaseValue) : leaseValue;
	const propertyNumericValue =
		typeof propertyValue === 'string'
			? parseFloat(propertyValue)
			: propertyValue;

	if (isNaN(leaseNumericValue) || isNaN(propertyNumericValue)) {
		return false;
	}

	return leaseNumericValue > propertyNumericValue;
};
