import { useLayoutContext } from 'Components';
import IconLock from 'ui/svg_icons/lock.svg';
import { useUnlockConditions } from 'api';
import { LeaseComp, SalesComp } from 'types';
import { useIsRowUnlocking } from './useIsRowUnlocking';
import { useUnlockComp } from './useUnlockComp';
import { ButtonProps, TooltipV2 } from '@compstak/ui-kit';
import styled, { css } from 'styled-components';
import { useToast } from 'providers/ToastProvider';
import { ExportOrUnlockButtonBase, ExportOrUnlockButtonType } from 'ui/UI';

type Props = {
	type: 'lease' | 'sale';
	comp: LeaseComp | SalesComp;
	buttonType: ExportOrUnlockButtonType;
} & ButtonProps;

export const UnlockCompButton = ({
	type,
	comp: row,
	buttonType,
	...props
}: Props) => {
	const { isCompact } = useLayoutContext();
	const { mutate, isLoading } = useUnlockComp(type);
	const { totalCost, hasEnoughPoints, canUnlockForFree } = useUnlockConditions({
		comps: [row],
	});
	const isRowUnlocking = useIsRowUnlocking({ id: row.id, type });
	const { addToast } = useToast();

	const tooltipText = canUnlockForFree
		? 'Unlock for free'
		: !hasEnoughPoints
			? 'Get more credits to unlock'
			: `Unlock for ${totalCost} Credits`;

	return (
		<TooltipV2 content={tooltipText}>
			{/* @ts-expect-error expected wrong onClick type */}
			<UnlockButton
				size={buttonType === 'standalone' ? 'l' : 'm'}
				variant="success"
				data-qa-id="unlock-btn"
				disabled={isLoading || !hasEnoughPoints || isRowUnlocking}
				isLoading={isLoading || isRowUnlocking}
				onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
					e.preventDefault();
					e.stopPropagation();
					if (!hasEnoughPoints) {
						return addToast({
							title: 'Get credits to unlock more comps.',
						});
					}
					if (isLoading || isRowUnlocking) return;
					// @ts-expect-error react query tripping
					mutate({ ids: [row.id] });
				}}
				buttonType={buttonType}
				{...props}
			>
				<IconLockStyled />
				<span>|</span>
				<span>{canUnlockForFree ? 'Free' : totalCost}</span>
				{!isCompact && buttonType !== 'row' && (
					<>
						<span>|</span>
						<span>Unlock</span>
					</>
				)}
			</UnlockButton>
		</TooltipV2>
	);
};

const IconLockStyled = styled(IconLock)`
	fill: ${(p) => p.theme.colors.white.white};
`;

const UnlockButton = styled(ExportOrUnlockButtonBase)`
	${({ disabled, buttonType, theme }) =>
		buttonType === 'row' &&
		!disabled &&
		css`
			background-color: transparent;
			color: transparent;
			transition: unset;

			${IconLockStyled} {
				fill: ${() => theme.colors.neutral.n70};
			}

			&:hover {
				${IconLockStyled} {
					fill: ${(p) => p.theme.colors.white.white};
				}
			}
		`}
`;
