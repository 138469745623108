import { useIsPortfolioAnalyticsAccessible } from 'hooks/useIsPortfolioAnalyticsAccessible';
import { PortfolioLayout } from './PortfolioLayout';
import { PortfolioUpsellView } from './Upsell/PortfolioUpsellView';

export const PortfolioView = () => {
	const isPortfolioAnalyticsAccessible = useIsPortfolioAnalyticsAccessible();
	return isPortfolioAnalyticsAccessible ? (
		<PortfolioLayout />
	) : (
		<PortfolioUpsellView />
	);
};
