import { useSubmarketsByMarket } from 'api/submarkets/useSubmarketsByMarket';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import Dollar from '../../../../../ui/svg_icons/dollar.svg';
import MycompsSavedsearch from '../../../../../ui/svg_icons/mycomps_savedsearch.svg';
import Plus from '../../../../../ui/svg_icons/plus.svg';
import Submarkets from '../../../../../ui/svg_icons/submarkets.svg';
import Time from '../../../../../ui/svg_icons/time.svg';
import { FromPortfolioModal } from '../../../Components/Modals/FromPortfolioModal';
import { CreateNewDataSetModal } from './DataSets/CreateNewDataSetModal';
import { SavedSearchesModal } from './SavedSearchesModal';
import { useModal } from 'providers/ModalProvider';
import { usePermissions } from 'Pages/Login/reducers';
import { useMarkets } from 'hooks/useMarkets';
import {
	createFirstDefaultChart,
	createSecondDefaultChart,
	createThirdDefaultChart,
} from '../../actions';
import {
	ModalButton,
	ModalButtons,
	ModalCenteredContent,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { routes } from 'router';
import { Chart } from 'Pages/Analytics/analytics';
import { useIsPortfolioAnalyticsAccessible } from 'hooks/useIsPortfolioAnalyticsAccessible';

type Props = {
	chartDraft: Chart;
	/** @default true */
	isFromScratch?: boolean;
};

export const NewDataSetModal = ({
	chartDraft,
	isFromScratch = true,
}: Props) => {
	const markets = useMarkets();
	const permissions = usePermissions();
	const { openModal, closeModal } = useModal();

	const dispatch = useDispatch();

	const [filters] = useFilters();
	const fallbackNYMarket = markets[1];

	const marketForDefaultChart =
		getFiltersMarkets(filters)
			.filter(({ id }) => permissions[id]?.analytics)
			.sort((market1, market2) =>
				market1.displayName
					.toLowerCase()
					.localeCompare(market2.displayName.toLowerCase())
			)[0] ?? fallbackNYMarket;
	const { data: marketSubmarkets } = useSubmarketsByMarket(
		marketForDefaultChart
	);

	const isPortfolioAnalyticsAccessible = useIsPortfolioAnalyticsAccessible({
		marketIds: getFiltersMarkets(filters).map(({ id }) => id),
	});

	const openNewDataSetModal = () => {
		openModal({
			modalContent: (
				<CreateNewDataSetModal chartDraft={chartDraft} onClose={closeModal} />
			),
			modalProps: {
				onClose: closeModal,
			},
		});
	};

	const openSavedSearchModal = () => {
		openModal({
			modalContent: (
				<SavedSearchesModal chartDraft={chartDraft} onClose={closeModal} />
			),
		});
	};

	const firstChart = () => {
		dispatch(createFirstDefaultChart(markets));
	};

	const secondChart = () => {
		dispatch(
			createSecondDefaultChart(
				marketForDefaultChart,
				markets,
				marketSubmarkets ?? []
			)
		);
	};

	const thirdChart = () => {
		dispatch(createThirdDefaultChart(marketForDefaultChart, markets));
	};

	return (
		<ModalCenteredContent>
			<ModalTitle>
				{isFromScratch
					? 'Start New Chart'
					: 'How would you like to add your data set?'}
			</ModalTitle>
			<Section>
				<h5>Use my own data series</h5>
				<OptionsContainer>
					<OwnSeriesOption onClick={openNewDataSetModal}>
						<Plus width={32} height={32} />
						<VerticalLine />
						<span>
							<div>Create</div>
							<b>New Data Set</b>
						</span>
					</OwnSeriesOption>
					<OwnSeriesOption onClick={openSavedSearchModal}>
						<MycompsSavedsearch width="32px" height="32px" />
						<VerticalLine />
						<span>
							<div>From</div>
							<b>Saved Search</b>
						</span>
					</OwnSeriesOption>
					{isPortfolioAnalyticsAccessible && (
						<>
							<OwnSeriesOption
								onClick={() =>
									openModal({
										modalContent: <FromPortfolioModal onClose={closeModal} />,
									})
								}
							>
								<MycompsSavedsearch width="32px" height="32px" />
								<VerticalLine />
								<span>
									<div>From</div>
									<b>Portfolio</b>
								</span>
							</OwnSeriesOption>
						</>
					)}
				</OptionsContainer>
			</Section>
			{isFromScratch && (
				<Section>
					<h5>Choose from pre-made charts</h5>
					<OptionsContainer>
						<PreMadeOption onClick={firstChart}>
							<PreMadeOptionHeader>
								<Dollar width={28} height={20} />
								<div>
									<div>Trends in</div>
									<b>Starting Rents</b>
								</div>
							</PreMadeOptionHeader>
							<HorizontalLine />
							<small>NYC vs. SF vs. LA vs. Chicago</small>
							<small>CBD, Office Buildings</small>
						</PreMadeOption>
						<PreMadeOption onClick={secondChart}>
							<PreMadeOptionHeader>
								<SubmarketsStyled width={21} height={21} />
								<div>
									<div>Compare</div>
									<b>Submarkets</b>
								</div>
							</PreMadeOptionHeader>
							<HorizontalLine />
							<small>{marketForDefaultChart.displayName}</small>
							<small>Office Buildings</small>
						</PreMadeOption>
						<PreMadeOption onClick={thirdChart}>
							<PreMadeOptionHeader>
								<Time width={24} height={24} />
								<div>
									<div>Compare</div>
									<b>Avg. Lease Term</b>
								</div>
							</PreMadeOptionHeader>
							<HorizontalLine />
							<small>{marketForDefaultChart.displayName}</small>
							<small>Class A vs. B vs. C</small>
						</PreMadeOption>
					</OptionsContainer>
				</Section>
			)}
			<ModalButtons>
				<ModalButton href={routes.analyticsProjects.path} onClick={closeModal}>
					View My Projects
				</ModalButton>
			</ModalButtons>
		</ModalCenteredContent>
	);
};

const Section = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	h5 {
		text-align: center;
		font-weight: 400;
		margin-bottom: 1rem;
	}
`;

const OptionsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 1rem;
`;

const OwnSeriesOption = styled.div`
	width: 290px;
	display: flex;
	padding: 1rem;
	align-items: center;
	border: 1px solid ${({ theme }) => theme.colors.gray.n70};
	border-radius: 4px;
	cursor: pointer;

	b {
		font-weight: 500;
	}

	&:hover {
		box-shadow: 0 0 13px 0 rgba(226, 231, 243, 0.9);
		color: ${({ theme }) => theme.colors.blue.blue400};
	}
`;

const PreMadeOption = styled.div`
	width: 190px;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.gray.gray200};
	border: 1px solid ${({ theme }) => theme.colors.gray.n70};
	border-radius: 4px;
	cursor: pointer;

	b {
		font-weight: 500;
	}

	&:hover {
		box-shadow: 0 0 13px 0 rgba(226, 231, 243, 0.9);
		color: ${({ theme }) => theme.colors.blue.blue400};
	}
`;

const PreMadeOptionHeader = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 1rem;
`;

const VerticalLine = styled.div`
	border-right: 1px solid ${({ theme }) => theme.colors.gray.n70};
	width: 0;
	margin: 0 0.75rem;
	height: 100%;
`;

const HorizontalLine = styled.hr`
	border-top: 1px solid ${({ theme }) => theme.colors.gray.n70};
	margin: 0.75rem 0;
	height: 0;
`;

const SubmarketsStyled = styled(Submarkets)`
	fill: ${({ theme }) => theme.colors.blue.blue400};
`;
