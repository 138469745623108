import React, {
	ChangeEventHandler,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import { isSet } from 'models/filters/util/getSetFilters';
import { FiltersTypesKeys } from 'models/filters/types';

import FilterContainer, { FilterErrors } from './Container';
import { FiltersObject } from 'models/filters/types';
import { trimString } from 'util/trimString';
import {
	getLabelForFilterAttribute,
	isNestedFilterSet,
	onNestedFilterChange,
} from 'models/filters/util/filterHelpers';
import { useFilterFieldContext } from 'Components/Filters/Fields/FilterFieldContextProvider';
import { CompType } from 'types';

export type ChildrenProps<T extends FiltersTypesKeys> = {
	attribute: T;
	compType: CompType;
	filter: FiltersObject[T];
	filters: FiltersObject;
	onFilterChange: (
		changes: Partial<FiltersObject>,
		processNestedFilters?: boolean
	) => void;
	touch: NoArgCallback;
	setError: (errors: FilterErrors) => void;
	nestedFilterIsSet: boolean;
};

type FilterBaseProps<T extends FiltersTypesKeys> = {
	attribute: T;
	children: (props: ChildrenProps<T>) => ReactNode;
	defaultSelected?: boolean;
	/** to filter by other things rather than just `label` */
	labelSynonyms?: string[];
	inputId?: string;
};

export const FilterBase = <T extends FiltersTypesKeys>({
	attribute,
	defaultSelected,
	children,
	labelSynonyms: _labelSynonyms,
	inputId,
}: FilterBaseProps<T>) => {
	const {
		compType,
		filters,
		onFilterChange: _onFilterChange,
		searchTerm,
		resetFiltersTrigger,
	} = useFilterFieldContext();

	const nestedFilterIsSet = useMemo(() => {
		return !!isNestedFilterSet(attribute, filters);
	}, [attribute, filters]);

	const filterIsSet = useMemo(() => {
		return !!isSet(filters[attribute], attribute) || nestedFilterIsSet;
	}, [filters, attribute, nestedFilterIsSet]);

	const [selected, setSelected] = useState(defaultSelected ?? filterIsSet);
	const [touched, setTouched] = useState(defaultSelected ?? false);
	const [errors, setErrors] = useState<FilterErrors>(null);

	const onFilterChange = (
		change: Partial<FiltersObject>,
		processNestedFilters = true
	) => {
		processNestedFilters
			? onNestedFilterChange(attribute, change, _onFilterChange)
			: _onFilterChange(change);
	};

	useEffect(() => {
		if (!touched) {
			setSelected(filterIsSet);
			setTouched(filterIsSet);
		}
	}, [filterIsSet]);

	useEffect(() => {
		if (typeof defaultSelected !== 'boolean') {
			return;
		}

		if (defaultSelected) {
			setSelected(true);
		} else if (!filterIsSet) {
			setSelected(false);
		}
	}, [defaultSelected]);

	useEffect(() => {
		if (typeof resetFiltersTrigger !== 'boolean') {
			return;
		}
		setSelected(filterIsSet);
		setTouched(filterIsSet);
	}, [resetFiltersTrigger]);

	const setError = (newErrors: FilterErrors) => {
		setErrors(newErrors);
	};

	const touch = useCallback(() => {
		setTouched(true);
	}, []);

	const toggleFilter: ChangeEventHandler<HTMLInputElement> = (event) => {
		if (event.target.checked) {
			setSelected(true);
		} else {
			deselect();
		}
	};

	const filter = filters[attribute];
	const deselect = () => {
		onFilterChange({
			[attribute]: null,
		});

		setSelected(false);
		setTouched(false);
		setErrors(null);
	};

	const childrenProps: ChildrenProps<T> = {
		attribute,
		compType,
		filter,
		filters,
		onFilterChange,
		setError,
		touch,
		nestedFilterIsSet,
	};

	const filterLabel = getLabelForFilterAttribute({ attribute, compType }) ?? '';
	const labelSynonyms = [filterLabel, ...(_labelSynonyms ?? [])];
	const isHidden =
		!!searchTerm &&
		!labelSynonyms.some((synonym) =>
			trimString(synonym).toLowerCase().includes(searchTerm)
		);

	return (
		<FilterContainer
			id={attribute}
			selected={selected}
			toggleFilter={toggleFilter}
			errors={errors}
			label={filterLabel}
			isHidden={isHidden}
			_inputId={inputId}
			filterIsSet={filterIsSet}
		>
			{children(childrenProps)}
		</FilterContainer>
	);
};
