import { useMarketsHaveFeature } from 'api';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useAppConfig } from 'Pages/Login/reducers';

type Props = {
	/** @default all markets */
	marketIds?: number[];
};

export const useIsPortfolioAnalyticsAccessible = (props?: Props) => {
	const featureFlags = useFeatureFlags();
	const { isEnterprise } = useAppConfig();

	const { data: hasPortfoliosAccess } = useMarketsHaveFeature({
		feature: 'portfolioAnalytics',
		method: 'some',
		marketIds:
			props?.marketIds && props?.marketIds?.length > 0
				? props?.marketIds
				: undefined,
	});

	return (
		(featureFlags['portfolio-analytics'] && hasPortfoliosAccess) && isEnterprise
	);
};
