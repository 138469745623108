import { useMarketsHaveFeature } from 'api';
import { useIsExchangePlusUser } from 'exchange/exchangePlus/exchangePlusStatus';
import { useIsExchange } from 'hooks';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { routes, useLocation, useMatch } from 'router';
import styled from 'styled-components';
import { HeaderAnchorLink, HeaderLink } from './UI';
import { useAnalyticsLinks } from './useAnalyticsLinks';
import { useMyCompstakLinks } from './useMyCompstakLinks';
import { useIsPortfolioAnalyticsAccessible } from 'hooks/useIsPortfolioAnalyticsAccessible';

export const useHeaderLinks = () => {
	const flags = useFeatureFlags();
	const isExchangePlusUser = useIsExchangePlusUser();
	const location = useLocation();
	const isExchange = useIsExchange();
	const isEnterprise = !isExchange;

	let leasesUrl = routes.searchLeasesHome.path;
	let salesUrl = routes.searchSalesHome.path;
	let propertiesUrl = routes.searchPropertiesHome.path;

	if (location.pathname.includes('/list')) {
		leasesUrl = routes.searchLeasesByView.toHref({ view: 'list' });
		salesUrl = routes.searchSalesByView.toHref({ view: 'list' });
		propertiesUrl = routes.searchPropertiesByView.toHref({ view: 'list' });
	} else if (location.pathname.includes('/map')) {
		leasesUrl = routes.searchLeasesByView.toHref({ view: 'map' });
		salesUrl = routes.searchSalesByView.toHref({ view: 'map' });
		propertiesUrl = routes.searchPropertiesByView.toHref({ view: 'map' });
	}

	const { data: hasSalesAccess } = useMarketsHaveFeature({
		feature: 'salesAccess',
		method: 'some',
	});

	const { data: hasAccessToPremierList } = useMarketsHaveFeature({
		feature: 'premierList',
		method: 'some',
	});

	const isHome = !!useMatch(routes.home.path);

	const isLeasesActive = !!location.pathname.match('leases');
	const isSalesActive = !!location.pathname.match('sales');
	const isPropertiesActive =
		!!location.pathname.match('properties') ||
		!!location.pathname.match('property');

	const isPremierList = !!useMatch(routes.premierList.path);
	const isPortfolio = !!useMatch(routes.portfolios.path);
	const isPortfolioById = !!useMatch(routes.portfolioByIdView.path);
	const isAnalyticsDashboard = !!useMatch(routes.analyticsDashboard.path);

	const analyticsLinks = useAnalyticsLinks();
	const myCompstakLinks = useMyCompstakLinks();

	const showPremierList = isEnterprise && hasAccessToPremierList;

	const isPortfolioAnalyticsAccessible = useIsPortfolioAnalyticsAccessible();
	const showPortfolios =
		isPortfolioAnalyticsAccessible ||
		(flags['portfolioUpsellFF'] && isEnterprise);

	const showMiddleDivider =
		showPremierList || showPortfolios || analyticsLinks != null;

	return (
		<>
			{isExchange && !isExchangePlusUser && flags['exchange-dashboard'] && (
				<HeaderLink
					to={routes.home.path}
					active={isHome || isAnalyticsDashboard}
				>
					Dashboard
				</HeaderLink>
			)}
			<HeaderLink
				to={leasesUrl}
				active={(isEnterprise && isHome) || isLeasesActive}
			>
				Leases
			</HeaderLink>
			{((isEnterprise && hasSalesAccess) || isExchange) && (
				<HeaderLink to={salesUrl} active={isSalesActive}>
					Sales
				</HeaderLink>
			)}
			<HeaderLink to={propertiesUrl} active={isPropertiesActive}>
				Properties
			</HeaderLink>
			<Divider />
			{showPremierList && (
				<HeaderLink to={routes.premierList.path} active={isPremierList}>
					Top of the Stak
				</HeaderLink>
			)}
			{analyticsLinks}
			{(isPortfolioAnalyticsAccessible || showPortfolios) && (
				<HeaderLink
					to={routes.portfolios.path}
					active={!!isPortfolio || !!isPortfolioById}
				>
					Portfolios
				</HeaderLink>
			)}
			{showMiddleDivider && <Divider />}
			{myCompstakLinks}
			<HeaderAnchorLink
				href={
					isExchange
						? 'https://help.compstak.com/'
						: 'https://support.compstak.com/hc/en-us'
				}
				target="_blank"
				rel="noopener noreferrer"
				data-qa-id="support-link"
			>
				Support
			</HeaderAnchorLink>
		</>
	);
};

const Divider = styled.div`
	width: 1px;
	height: 0.625rem;
	background-color: ${(p) => p.theme.colors.blue.blue500};
	margin: 0 0.25rem;
`;
