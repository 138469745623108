import {
	FilterAction,
	FILTERS_CHANGE,
	FILTERS_INIT,
	FILTERS_RESET,
} from 'actions/filters';
import {
	COLLAPSE_SUBMARKETS_LIST,
	DESELECT_MAP_CONTROL,
	FOCUS_TYPEAHEAD,
	LOAD_SUBMARKETS,
	MAP_CONTROLS,
	REMOVE_SUBMARKETS,
	SearchAction,
	SELECT_MAP_CONTROL,
	SET_MY_COMPS,
} from 'actions/search';
import { SelectionAction, SELECT_ALL_COMPS } from 'actions/selection';
import { SubmarketPolygon } from 'api/submarketPolygons/useSubmarketPolygons';
import produce, { Draft } from 'immer';
import sortBy from 'lodash/sortBy';
import { FiltersObject } from 'models/filters/types';
import { LOGOUT, LogoutAction } from 'Pages/Login/actions';
import { ActiveMapControl } from 'types';
import {
	GetPromiseActionType,
	getPromiseActionType,
} from 'types/redux-promise-middleware';
import { useAppSelector } from 'util/useAppSelector';

type SubmarketsState = { [marketId: number]: SubmarketPolygon[] } | null;

export type SearchState = {
	activeMapControl: ActiveMapControl;
	activeMapControlSilent: boolean;
	focusTypeahead: boolean;
	isMyComps: boolean;
	loadingSubmarkets: boolean;
	selectAllPending: boolean;
	marketIdToSubmarkets: SubmarketsState;
	submarketsListCollapsed: boolean;
	resetFiltersTrigger: boolean | null;
};

const initialState: SearchState = {
	activeMapControl: null,
	activeMapControlSilent: false, // if true - don't trigger polygon/radius/submarket search
	focusTypeahead: false,
	isMyComps: false,
	loadingSubmarkets: false,
	selectAllPending: false,
	marketIdToSubmarkets: null,
	submarketsListCollapsed: false,
	resetFiltersTrigger: null,
};

type ProcessedSelectionAction = Extract<
	SelectionAction,
	{ type: GetPromiseActionType<typeof SELECT_ALL_COMPS> }
>;

type ProcessedFilterAction = Extract<
	FilterAction,
	| { type: typeof FILTERS_CHANGE }
	| { type: typeof FILTERS_RESET }
	| { type: typeof FILTERS_INIT }
>;

const searchReducer = (
	state: SearchState = initialState,
	action:
		| SearchAction
		| ProcessedSelectionAction
		| ProcessedFilterAction
		| LogoutAction
): SearchState => {
	return produce(state, (draftState: Draft<SearchState>) => {
		switch (action.type) {
			case getPromiseActionType(SELECT_ALL_COMPS, 'PENDING'):
				draftState.selectAllPending = true;
				return;

			case getPromiseActionType(SELECT_ALL_COMPS, 'FULFILLED'):
			case getPromiseActionType(SELECT_ALL_COMPS, 'REJECTED'):
				draftState.selectAllPending = false;
				return;

			case SELECT_MAP_CONTROL:
				draftState.activeMapControl = action.payload.id;
				draftState.activeMapControlSilent = action.payload.silent;
				return;

			case DESELECT_MAP_CONTROL:
				draftState.activeMapControl = null;
				draftState.activeMapControlSilent = action.payload.silent;
				return;

			case FILTERS_RESET:
			case FILTERS_CHANGE:
			case FILTERS_INIT: {
				if (action.type === FILTERS_INIT || action.payload.context === 'main') {
					const newFilters: Partial<FiltersObject> =
						action.type === FILTERS_RESET
							? {
									radius: null,
									polygon: null,
									submarkets: null,
									opportunityZoneId: null,
									...action.payload.defaults,
								}
							: action.type === FILTERS_INIT
								? action.payload.initialFilters.main
								: action.payload.changes;
					if (
						newFilters.radius === null &&
						newFilters.polygon === null &&
						newFilters.submarkets === null &&
						newFilters.opportunityZoneId === null
					) {
						draftState.activeMapControl = null;
					}

					if (newFilters.submarkets) {
						draftState.activeMapControl = MAP_CONTROLS.SUBMARKETS;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.opportunityZoneId) {
						draftState.activeMapControl = MAP_CONTROLS.OPPORTUNITY_ZONES;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.radius) {
						draftState.activeMapControl = MAP_CONTROLS.RADIUS;
						draftState.activeMapControlSilent = true;
					}

					if (newFilters.polygon) {
						if (state.activeMapControl !== MAP_CONTROLS.SEARCH_WITHIN_VIEW) {
							draftState.activeMapControl = MAP_CONTROLS.POLYGON;
							draftState.activeMapControlSilent = true;
						}
					}
				}

				if (action.type === 'FILTERS_RESET') {
					draftState.resetFiltersTrigger = !draftState.resetFiltersTrigger;
				}

				return;
			}

			case getPromiseActionType(LOAD_SUBMARKETS, 'PENDING'):
				draftState.loadingSubmarkets = true;
				return;

			case getPromiseActionType(LOAD_SUBMARKETS, 'FULFILLED'): {
				const marketIds = action.meta.marketIds;
				const submarkets = action.payload;
				draftState.marketIdToSubmarkets = marketIds.reduce<{
					[marketId: number]: SubmarketPolygon[];
				}>((acc, marketId, index) => {
					const marketSubmarkets = submarkets[index];
					const sortedMarketSubmarkets = sortBy(
						marketSubmarkets,
						(submarket) => submarket.properties.name
					);
					acc[marketId] = sortedMarketSubmarkets;
					return acc;
				}, {});
				draftState.loadingSubmarkets = false;
				return;
			}

			case REMOVE_SUBMARKETS:
				draftState.marketIdToSubmarkets = null;
				return;

			case COLLAPSE_SUBMARKETS_LIST:
				draftState.submarketsListCollapsed = action.payload;
				return;

			case SET_MY_COMPS:
				draftState.isMyComps = action.payload;
				return;

			case FOCUS_TYPEAHEAD:
				draftState.focusTypeahead = !draftState.focusTypeahead;
				return;

			case LOGOUT:
				return initialState;
		}
	});
};

export default searchReducer;

export const useSearch = () => {
	const search = useAppSelector((state) => ({
		...state.searchReducer,
		...state.mapSearchResultsV2,
	}));

	return search;
};

export type SearchAndMapSearchState = ReturnType<typeof useSearch>;
