import { UseQueryOptions } from '@tanstack/react-query';
import {
	SearchLeasesResponse,
	SearchMufaPropertiesResponse,
	SearchPropertiesResponse,
	SearchSalesResponse,
	composeEnabled,
	useSearchMufaPropertiesQuery,
	useSearchPropertiesQuery,
	useSearchSalesQuery,
} from 'api';
import { useSearchLeasesQuery } from 'api/searchLeases/useSearchLeasesQuery';
import { FiltersObject } from 'models/filters/types';
import { filtersToServerJSON } from 'models/filters/util';
import { CompType } from 'types';
import { useIsMultifamily } from './useIsMultifamily';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { useMemo } from 'react';

type UseSearchSingleCompParams = {
	compType: CompType;
	filters: Partial<FiltersObject>;
};

export const useSearchSingleComp = (
	{ compType, filters }: UseSearchSingleCompParams,
	options?:
		| UseQueryOptions<SearchLeasesResponse>
		| UseQueryOptions<SearchSalesResponse>
		| UseQueryOptions<SearchPropertiesResponse>
		| UseQueryOptions<SearchMufaPropertiesResponse>
) => {
	const filter = useMemo(() => {
		return filtersToServerJSON(filters);
	}, [filters]);

	const { data: leaseData, ...restLeaseQueryResult } = useSearchLeasesQuery(
		{
			filter,
			limit: 1,
			offset: 0,
			sort: 'executionQuarter',
			order: 'desc',
		},
		{
			...(options as UseQueryOptions<SearchLeasesResponse>),
			enabled: composeEnabled(compType === 'lease', options?.enabled),
		}
	);

	const { data: saleData, ...restSaleQueryResult } = useSearchSalesQuery({
		params: {
			filter,
			limit: 1,
			offset: 0,
			sort: 'dateCreated',
			order: 'asc',
		},
		...(options as UseQueryOptions<SearchSalesResponse>),
		enabled: composeEnabled(compType === 'sale', options?.enabled),
	});

	const isMufa = useIsMultifamily({
		markets: getFiltersMarkets(filters),
	});

	const { data: propertyData, ...restPropertyQueryResult } =
		useSearchPropertiesQuery(
			{
				filter,
				limit: 1,
				offset: 0,
				sort: 'buildingSize',
				order: 'desc',
			},
			{
				...(options as UseQueryOptions<SearchPropertiesResponse>),
				enabled: composeEnabled(compType === 'property' && !isMufa, options?.enabled),
			}
		);

	const { data: mufaPropertyData, ...restMufaPropertyQueryResult } =
		useSearchMufaPropertiesQuery(
			{
				filter,
				limit: 1,
				offset: 0,
				sort: 'buildingSize',
				order: 'desc',
			},
			{
				...(options as UseQueryOptions<SearchMufaPropertiesResponse>),
				enabled: composeEnabled(compType === 'property' && isMufa, options?.enabled),
			}
		);

	const data = (() => {
		if (compType === 'lease') {
			return leaseData;
		}
		if (compType === 'sale') {
			return saleData;
		}
		if (compType === 'property') {
			return isMufa ? mufaPropertyData : propertyData;
		}
	})();
	const comp = (() => {
		if (compType === 'lease') {
			return leaseData?.comps[0];
		}
		if (compType === 'sale') {
			return saleData?.comps[0];
		}
		if (compType === 'property') {
			return isMufa ? mufaPropertyData?.properties[0] : propertyData?.properties[0];
		}
	})();
	const isFetching =
		restLeaseQueryResult.isFetching ||
		restSaleQueryResult.isFetching ||
		restPropertyQueryResult.isFetching ||
		restMufaPropertyQueryResult.isFetching;

	return { data, isFetching, comp };
};
